import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";
import TimeCardHeader from './TimeCardHeader';
import TimeCardStatus from './TimeCardStatus';
import TimeCardActions from './TimeCardActions';
import TimeCardEntries from './TimeCardEntries';

export default function TimeCardDialog({
  open,
  onClose,
  tcdate,
  statuscolorTimecard,
  status,
  clockbtnlabel,
  workbreak,
  showingHistory,
  clockedin,
  handleClockin,
  handleBreak,
  timecards,
  oneDayBack,
  oneDayForward,
  fwdnavdisabled,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <TimeCardHeader
          tcdate={tcdate}
          oneDayBack={oneDayBack}
          oneDayForward={oneDayForward}
          fwdnavdisabled={fwdnavdisabled}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TimeCardStatus statuscolorTimecard={statuscolorTimecard} status={status} />
          </Grid>
          <Grid item xs={12}>
            <TimeCardActions
              clockbtnlabel={clockbtnlabel}
              workbreak={workbreak}
              showingHistory={showingHistory}
              clockedin={clockedin}
              handleClockin={handleClockin}
              handleBreak={handleBreak}
            />
          </Grid>
          <Grid item xs={12}>
            <TimeCardEntries timecards={timecards} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}