// src/components/Hooks/useAuth.js
import { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';
import { useNavigate } from 'react-router-dom';

export function useAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Check for an existing session on mount
  useEffect(() => {
    const checkSession = async () => {
      try {
        //console.log("Fetching session...");
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        //console.log("Session:", session);
        if (sessionError) throw sessionError;
        setUser(session?.user || null);
      } catch (error) {
        console.error('Error fetching session:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    checkSession();

    // Listen for auth state changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      //console.log("Auth state changed:", event, session);
      if (event === 'SIGNED_IN' && !session.user.email_confirmed_at) {
        navigate('/verify-email'); // Redirect to email verification page
      }
      setUser(session?.user || null);
    });

    // Cleanup subscription on unmount
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [navigate]);

  // Login function
  const login = async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      console.log("Logging in...");
      const { data, error: loginError } = await supabase.auth.signInWithPassword({ email, password });
      console.log("Login response:", data);
      if (loginError) throw loginError;
      return data;
    } catch (error) {
      console.error('Login error:', error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Logout function
  const logout = async () => {
    setLoading(true);
    setError(null);
    try {
      console.log("Logging out...");
      const { error: logoutError } = await supabase.auth.signOut();
      if (logoutError) throw logoutError;
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Register function
  const register = async (email, password, metadata = {}) => {
    setLoading(true);
    setError(null);
    try {
      console.log("Registering...");
      const { data, error: registerError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: metadata,
        },
      });
      console.log("Registration response:", data);
      if (registerError) throw registerError;
      return data;
    } catch (error) {
      console.error('Registration error:', error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Password reset function
  const resetPassword = async (email) => {
    setLoading(true);
    setError(null);
    try {
      console.log("Resetting password...");
      const { data, error: resetError } = await supabase.auth.resetPasswordForEmail(email);
      console.log("Password reset response:", data);
      if (resetError) throw resetError;
      return data;
    } catch (error) {
      console.error('Password reset error:', error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { user, loading, error, login, logout, register, resetPassword };
}