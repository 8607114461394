import React, { useState, useEffect } from 'react';
import { StoreContext } from '../Hooks/store';

import { DataGrid } from '@mui/x-data-grid';
import { notify } from "../Toast";

import { localTime } from '../Utils/localTime';
import { prettyTime } from '../Utils/prettyTime';

import sqlitedb from '../sqlitedb';

import { v4 as uuidv4 } from "uuid";
import { hashCode } from '../Utils/hashCode';

//import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';

export default function ExerciseTimer() {
  //const { trackInteraction } = useUserInteractionTracker();
  useEffect(() => {
    //trackInteraction('page_view', { pageName: 'Workout' });
    notify('Loading....')
    getTimeCards(new Date().setHours(24, 0, 0, 0))
},
    []
);
  const [timecards, setTimecards] = useState([]);
  async function getTimeCards(tcData) {
    try {
        return await sqlitedb.getTimeCardEntriesForLast5days(tcData).then(x => {
            const temp = [];

            
            if (!x) {
                return;
            }
            Object.entries(x).map(([, v]) => {

              
              const hashedV1 = hashCode(uuidv4());
              let newObj = {
                    id: hashedV1,
                    utc_start: localTime(v.utc_start),
                    utc_end: localTime(v.utc_end),
                    utc_saved: localTime(v.utc_saved),
                    event_name: v.event_name ,
                    event_duration_ms: v.event_duration_ms > 0 ? v.event_duration_ms/1000 : v.event_duration_ms
                };
                temp.push(newObj);
            })

            console.log(temp);

            setTimecards(temp)

        })
    } catch (error) {
        console.log(error)
        notify(error.message)
    }
}

  // const { exercisestore } = React.useContext(StoreContext);
  // const [doneworkout, setDoneworkout] = exercisestore;
  // const [activeStep, setActiveStep] = useState(-1);
  // const [done, setDone] = useState(false);
  


  function getRowId(row) {
    return hashCode(uuidv4()); //row.internalId;
  }
  
  // const columns = [
  //   { field: 'col1', headerName: 'Column 1', width: 150 },
  //   { field: 'col2', headerName: 'Column 2', width: 150 },
  // ];
  
  const columns = [
    { field: 'id', headerName: 'Id', width: 100, hide: true },
    { field: 'utc_start', headerName: 'Start At', width: 150 },
    { field: 'utc_end', headerName: 'End At', width: 150 },
    { field: 'utc_saved', headerName: 'Saved On', width: 150 },
    { field: 'event_name', headerName: 'Event', width: 150 },
    { field: 'event_duration_ms', headerName: 'Duration (s)', width: 150 }
  ];

  return (
    <>
    <div style={{ height: 300, width: '100%' }}>
      <DataGrid rows={timecards} columns={columns} getRowId={getRowId} />
      {/* <DataGrid rows={timecards} columns={columns} /> */}
    </div>
    </>
  );


}