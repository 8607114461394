import React from 'react';
import styled from 'styled-components';
import ee from 'event-emitter';

// Styled component for the notification container
const Container = styled.div`
  background-color: ${(props) =>
    props.type === 'error' ? '#ff4d4d' : props.type === 'success' ? '#4CAF50' : '#444'};
  color: white;
  padding: 16px;
  position: fixed;
  top: ${(props) => (props.visible ? '16px' : '-100px')};
  right: 16px;
  z-index: 999;
  transition: top 0.5s ease, opacity 0.5s ease;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: ${(props) => (props.visible ? 1 : 0)};

  > i {
    margin-left: 8px;
  }

  > button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: auto;
    font-size: 16px;
  }
`;

// Event emitter instance
const emitter = new ee();

/**
 * Trigger a notification.
 * @param {string} msg - The message to display.
 * @param {string} type - The type of notification (e.g., 'success', 'error', 'info').
 * @param {boolean} persist - Whether the notification should persist (default: false).
 */
export const notify = (msg, type = 'info', persist = false) => {
  emitter.emit('notification', { msg, type, persist });
};

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [], // Array to hold multiple notifications
    };

    this.timeouts = new Map(); // Map to track timeouts for each notification

    // Subscribe to notification events
    emitter.on('notification', (data) => {
      this.addNotification(data.msg, data.type, data.persist);
    });
  }

  /**
   * Add a notification to the state.
   * @param {string} msg - The message to display.
   * @param {string} type - The type of notification.
   * @param {boolean} persist - Whether the notification should persist.
   */
  addNotification = (msg, type, persist = false) => {
    const id = Date.now(); // Unique ID for each notification
    this.setState((prevState) => ({
      notifications: [...prevState.notifications, { id, msg, type, persist }],
    }));

    // If the notification is not persistent, remove it after 3 seconds
    if (!persist) {
      const timeout = setTimeout(() => {
        this.removeNotification(id);
      }, 3000);
      this.timeouts.set(id, timeout);
    }
  };

  /**
   * Remove a notification from the state.
   * @param {number} id - The ID of the notification to remove.
   */
  removeNotification = (id) => {
    this.setState((prevState) => ({
      notifications: prevState.notifications.filter((note) => note.id !== id),
    }));
    this.timeouts.delete(id);
  };

  componentWillUnmount() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.timeouts.clear();
  }

  render() {
    const { notifications } = this.state;

    const getIcon = (type) => {
      switch (type) {
        case 'success':
          return '✔️';
        case 'error':
          return '❌';
        default:
          return 'ℹ️';
      }
    };

    return (
      <>
        {notifications.map((note, index) => (
          <Container
            key={note.id}
            visible={true}
            type={note.type}
            style={{ top: `${16 + index * 60}px` }} // Adjust top position dynamically
          >
            {getIcon(note.type)} {note.msg}
            {note.persist && (
              <button onClick={() => this.removeNotification(note.id)}>×</button>
            )}
          </Container>
        ))}
      </>
    );
  }
}