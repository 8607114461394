import React, { useState, useCallback, useContext } from 'react';
import { IconButton, Container, Dialog, DialogTitle, DialogContent, Divider, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { notify } from '../Toast';
import CurrentDate from '../Utils/CurrentDate';
import ActivityTimer from './ActivityTimer';
import { StoreContext } from '../Hooks/store';
import { CompletedActivities } from './CompletedActivities';
import { addToStringArray, sortStringArray } from '../Utils/arrayUtils';
import { CancelBtn, SaveBtn, InputText } from './ActivityTimer.styled';
import useStickyState from '../Hooks/useStickyState';
import { useActivity } from '../hooks/useActivity';

export default function Homework({ open, onClose }) {
  const store = useContext(StoreContext) || {};
  const [showaddactivity, setShowaddactivity] = store?.activitiesaddvisible || [false, () => {}];
  const [user] = store?.user || [null]; // Get the authenticated user from the store

  const { activities, loading, error, addActivity } = useActivity(user?.id); // Pass the user's ID to useActivity

  const [newChoiceTxt, setNewChoiceTxt] = useState('');
  const [choices, setChoices] = useStickyState(
    [
      '', 'Break', 'Lunch', 'Piano', 'Math', 'DreamBox', 'Math-Packet', 'Math-IXL',
      'Reading', 'Comprehension', 'Writing', 'Science', 'Walk', 'Workout',
    ],
    'homeworkchoices'
  );

  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const addChoice = useCallback(async () => {
    if (choices.includes(newChoiceTxt)) {
      notify(`Won't add duplicate: ${newChoiceTxt}`);
      return;
    }

    const updatedChoices = addToStringArray(choices, newChoiceTxt).sort(sortStringArray);
    setChoices(updatedChoices);
    await addActivity({ name: newChoiceTxt });
    notify(`Added ${newChoiceTxt}`);
  }, [choices, newChoiceTxt, setChoices, addActivity]);

  // Show a loading indicator while fetching data
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show an error message if there's an error
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container>
      <Dialog open={open} onClose={handleOnClose}>
        <DialogTitle
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '11pt',
            backgroundColor: '#00416e',
            color: '#fff',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <CurrentDate options={dateOptions} />
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: (theme) => theme.spacing(1),
              top: (theme) => theme.spacing(1),
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleOnClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: '#fff',
            minWidth: '320px',
            marginLeft: '-5px',
          }}
        >
          <Box>
            <strong>
              <ActivityTimer color="#0080BB" activity="Begin/End" />
            </strong>
          </Box>
          <Divider />
          <Box>
            <ActivityTimer color="orange" activity="Select" placeholder="Select activity:" options={choices} />
          </Box>
          <Box>
            {showaddactivity && (
              <>
                <InputText
                  maxLength="30"
                  onChange={(e) => setNewChoiceTxt(e.target.value)}
                  placeholder="Enter activity..."
                />
                <CancelBtn inputColor="#333" onClick={() => setShowaddactivity(false)}>
                  Cancel
                </CancelBtn>
                <SaveBtn inputColor="#0080BB" onClick={addChoice}>
                  Save
                </SaveBtn>
              </>
            )}
          </Box>
          <Box>
            <ActivityTimer color="green" activity="Custom" placeholder="" />
          </Box>
          <Divider />
          <CompletedActivities activities={activities} />
        </DialogContent>
      </Dialog>
    </Container>
  );
}