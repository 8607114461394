import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  Chip,
  Stack
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAssignments } from '../hooks/useAssignments.js';
import { format } from 'date-fns';

export default function AssignmentList() {
  const { assignments, updateAssignment, deleteAssignment } = useAssignments();

  const handleComplete = async (id, currentStatus) => {
    try {
      await updateAssignment(id, {
        status: currentStatus === 'completed' ? 'pending' : 'completed'
      });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this assignment?')) {
      try {
        await deleteAssignment(id);
      } catch (error) {
        console.error('Error deleting assignment:', error);
      }
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Assignment List
      </Typography>
      
      {assignments.length === 0 ? (
        <Typography color="textSecondary" align="center" sx={{ mt: 4 }}>
          No assignments found
        </Typography>
      ) : (
        <List>
          {assignments.map((assignment) => (
            <ListItem
              key={assignment.id}
              sx={{
                mb: 1,
                border: 1,
                borderColor: 'divider',
                borderRadius: 1,
              }}
              secondaryAction={
                <Box>
                  <IconButton 
                    edge="end" 
                    onClick={() => handleComplete(assignment.id, assignment.status)}
                    color={assignment.status === 'completed' ? 'success' : 'default'}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                  <IconButton 
                    edge="end" 
                    onClick={() => handleDelete(assignment.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText
                primary={assignment.title}
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Due: {format(new Date(assignment.due_date), 'MMM d, yyyy')}
                    </Typography>
                    {assignment.description && (
                      <Typography variant="body2" color="textSecondary">
                        {assignment.description}
                      </Typography>
                    )}
                  </>
                }
              />
              <Stack direction="row" spacing={1} sx={{ mr: 2 }}>
                <Chip
                  label={assignment.priority}
                  color={
                    assignment.priority === 'high' ? 'error' :
                    assignment.priority === 'medium' ? 'warning' : 'success'
                  }
                  size="small"
                />
                <Chip
                  label={assignment.status}
                  color={assignment.status === 'completed' ? 'success' : 'default'}
                  size="small"
                />
              </Stack>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}