import React from 'react';
import { Dialog } from '@mui/material';
import AssignmentForm from './AssignmentForm';
import { StoreContext } from '../Hooks/store';

export default function AssignmentFormDialog() {
  const { modaldialog } = React.useContext(StoreContext);
  const [loadedmodal, setLoadedmodal] = modaldialog;

  // Handle form submission
  const handleSubmit = (formData) => {
    console.log('Form Data Submitted:', formData);
    // Perform any necessary actions with the form data (e.g., API call, state update)
    setLoadedmodal(null); // Close the dialog after submission
  };

  // Handle dialog close
  const handleClose = () => {
    setLoadedmodal(null);
  };

  return (
    <Dialog 
      open={loadedmodal === 'AssignmentForm'} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <AssignmentForm 
        open={loadedmodal === 'AssignmentForm'} // Pass the open state
        onClose={handleClose} // Pass the close handler
        onSubmit={handleSubmit} // Pass the submit handler
      />
    </Dialog>
  );
}