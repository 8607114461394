import React from 'react';
import { StoreContext } from '../Hooks/store';
import AssignmentList from './AssignmentList';
import AssignmentFormDialog from './AssignmentFormDialog';
import { notify } from '../Toast';
//import { interactionTracker } from '../Utils/InteractionTracker'; // Import the singleton
import styles from './styles.module.css';

export default function Assignment(props) {
  const { modaldialog } = React.useContext(StoreContext);
  const [loadedmodal, setLoadedmodal] = modaldialog;

  // React.useEffect(() => {
  //   interactionTracker.trackInteraction('page_view', { pageName: 'Assignments' }); // Use the singleton
  // }, []);

  return (
    <div className={styles.assignmentContainer}>
      <AssignmentList />
      <AssignmentFormDialog />
    </div>
  );
}

export { default as AssignmentList } from './AssignmentList';
export { default as AssignmentItem } from './AssignmentItem';
export { default as AssignmentForm } from './AssignmentForm';
export { default as AssignmentFormDialog } from './AssignmentFormDialog';