import React, { useState, useContext, useMemo, useRef, useEffect } from 'react';
import { notify } from "../Toast";
import { prettyTime } from '../Utils/prettyTime';
import { StoreContext } from '../Hooks/store';
import { Divider, TextField, Button, Box, Typography } from '@mui/material';
import { IconArrowDownUp } from '../Shared/IconArrowDownUp';
import EditIcon from '@mui/icons-material/Edit';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isValid, parseISO } from 'date-fns';
import { updateActivity } from '../../api/activities'; // Import the updateActivity function

export function CompletedActivities() {
  const { activitiescustom, activitiesselected, activitiessaved } = useContext(StoreContext);
  const [donecustom] = activitiescustom;
  const [doneselect] = activitiesselected;
  const [savedactivities, setSavedActivities] = activitiessaved;

  const [editingItem, setEditingItem] = useState(null);
  const [editedActivity, setEditedActivity] = useState({
    activity: '',
    utc_start: null,
    utc_end: null,
  });
  const [isDescending, setIsDescending] = useState(true);

  // Combine and sort completed items
  const completedItems = useMemo(() => {
    const combined = [...donecustom, ...doneselect, ...savedactivities];
    return combined.sort((a, b) => (isDescending ? b.utc_saved - a.utc_saved : a.utc_saved - b.utc_saved));
  }, [donecustom, doneselect, savedactivities, isDescending]);

  const doneCount = useMemo(() => completedItems.length, [completedItems]);

  const toggleSortOrder = () => {
    setIsDescending((prev) => !prev);
    notify('Loading....');
  };

  const handleEditClick = (item) => {
    if (item.utc_start === '') return; // Disable editing for day splitters

    // Convert utc_start and utc_end (milliseconds) to Date objects
    const utcStart = item.utc_start ? new Date(item.utc_start) : null;
    const utcEnd = item.utc_end ? new Date(item.utc_end) : null;

    // Ensure valid dates
    if (utcStart && !isValid(utcStart)) {
      console.error('Invalid utc_start:', item.utc_start);
      return;
    }
    if (utcEnd && !isValid(utcEnd)) {
      console.error('Invalid utc_end:', item.utc_end);
      return;
    }

    setEditingItem(item);
    setEditedActivity({
      ...item,
      utc_start: utcStart, // Use the Date object directly
      utc_end: utcEnd, // Use the Date object directly
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedActivity((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateTimeChange = (name, date) => {
    setEditedActivity((prev) => ({ ...prev, [name]: date }));
  };

  const handleSaveEdit = async () => {
    if (!editedActivity.activity || !editedActivity.utc_start || !editedActivity.utc_end) {
      notify('Please fill in all fields.');
      return;
    }

    // Validate dates
    if (!isValid(editedActivity.utc_start) || !isValid(editedActivity.utc_end)) {
      notify('Invalid date values. Please check the start and end times.');
      return;
    }

    // Convert Date objects back to Unix timestamps (milliseconds)
    const utcStart = editedActivity.utc_start.getTime();
    const utcEnd = editedActivity.utc_end.getTime();

    // Recalculate elapsed time
    const elapsed = (utcEnd - utcStart) / 1000; // Convert to seconds
    const elapsedlabel = prettyTime(elapsed * 1000); // Format elapsed time

    // Update the edited activity with the recalculated elapsed time
    const updatedActivity = {
      ...editedActivity,
      utc_start: utcStart, // Use the Unix timestamp
      utc_end: utcEnd, // Use the Unix timestamp
      elapsed,
      elapsedlabel,
    };

    try {
      // Save the updated activity to the database
      await updateActivity(updatedActivity);

      // Update the local state
      const updatedActivities = savedactivities.map((item) =>
        item.id === editingItem.id ? updatedActivity : item
      );

      setSavedActivities(updatedActivities);
      setEditingItem(null);
      notify('Activity updated successfully.');
    } catch (error) {
      console.error('Error saving activity:', error);
      notify('Failed to update activity. Please try again.');
    }
  };

  const ActivitiesHeader = React.memo(() => (
    <Box sx={{ backgroundColor: '#fff' }}>
      <Box>
        <Typography
          sx={{
            fontSize: '10pt',
            fontWeight: '500',
            textAlign: 'left',
            margin: '0 5px 0 15px',
            width: '125px',
            display: 'inline-block',
            borderRight: 'solid 1px #ccc',
          }}
        >
          <strong>Activity</strong>
        </Typography>
        <Typography
          sx={{
            fontSize: '10pt',
            fontWeight: '500',
            textAlign: 'left',
            marginLeft: '5px',
          }}
        >
          <strong>Duration</strong>
        </Typography>
      </Box>
      <Divider />
    </Box>
  ));

  const CompletedItem = React.memo(({ item, onEditClick }) => {
    const isDaySplitter = item.utc_start === '';

    // Format the start and end times using local_start and local_end, with fallback to utc_start and utc_end
    const formatTime = (dateString, fallbackTimestamp) => {
      if (!dateString && !fallbackTimestamp) return 'N/A'; // Fallback for empty or null values

      // Try parsing the dateString first
      if (dateString) {
        const dateFromString = parseISO(dateString);
        if (isValid(dateFromString)) {
          return format(dateFromString, 'hh:mm a');
        }
      }

      // If dateString is invalid, try using the fallbackTimestamp
      if (fallbackTimestamp) {
        const dateFromTimestamp = new Date(fallbackTimestamp);
        if (isValid(dateFromTimestamp)) {
          return format(dateFromTimestamp, 'hh:mm a');
        }
      }

      return 'N/A'; // Fallback if both attempts fail
    };

    const startTime = formatTime(item.local_start, item.utc_start);
    const endTime = formatTime(item.local_end, item.utc_end);

    return (
      <>
        <Box
          sx={{
            display: 'table-row',
          }}
        >
          <Typography
            sx={{
              fontSize: '10pt',
              fontWeight: '500',
              textAlign: 'left',
              margin: '0 5px 0 15px',
              width: '125px',
              display: 'inline-block',
              borderRight: 'solid 1px #ccc',
              ...(isDaySplitter && {
                fontFamily: 'Montserrat',
                fontSize: '10pt',
                fontWeight: '600',
                textAlign: 'left',
                marginLeft: '1px',
                color: '#00416e',
                display: 'none',
              }),
            }}
          >
            {isDaySplitter ? '' : item.activity}
          </Typography>
          <Typography
            sx={{
              fontSize: '10pt',
              fontWeight: '500',
              textAlign: 'left',
              marginLeft: '5px',
              display: 'table-cell',
              verticalAlign: 'top',
              ...(isDaySplitter && {
                fontFamily: 'Montserrat',
                fontSize: '10pt',
                fontWeight: '600',
                textAlign: 'left',
                marginLeft: '50px',
                color: '#00416e',
              }),
            }}
          >
            {isDaySplitter ? item.activity : `${startTime}-${endTime} (${prettyTime(1000 * item.elapsed)})`}
          </Typography>
          {!isDaySplitter && (
            <Box
              sx={{
                cursor: 'pointer',
                marginLeft: '8px',
                color: '#1976d2',
              }}
              onClick={() => onEditClick(item)}
            >
              <EditIcon />
            </Box>
          )}
        </Box>
        <Divider />
      </>
    );
  });

  const EditForm = React.memo(({ item, onSave, onCancel }) => {
    const activityInputRef = useRef(null); // Ref for the activity TextField

    // Focus the activity input field when the form is rendered
    useEffect(() => {
      if (activityInputRef.current) {
        activityInputRef.current.focus();
      }
    }, []);

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          sx={{
            padding: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            marginBottom: '16px',
          }}
        >
          <TextField
            name="activity"
            label="Activity"
            value={editedActivity.activity || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            inputRef={activityInputRef} // Attach the ref to the TextField
          />
          <DateTimePicker
            label="Start Time"
            value={editedActivity.utc_start} // Use the Date object
            onChange={(date) => handleDateTimeChange('utc_start', date)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
          <DateTimePicker
            label="End Time"
            value={editedActivity.utc_end} // Use the Date object
            onChange={(date) => handleDateTimeChange('utc_end', date)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '8px',
              marginTop: '16px',
            }}
          >
            <Button variant="contained" color="primary" onClick={onSave}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    );
  });

  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '13pt',
          fontWeight: '700',
          textAlign: 'center',
          marginLeft: '5px',
          color: '#000',
        }}
      >
        Completed Activities... ({doneCount}) &nbsp;&nbsp;
        <span onClick={toggleSortOrder}>
          <IconArrowDownUp />
        </span>
      </Typography>
      <Divider />
      <Box sx={{ backgroundColor: '#fff' }}>
        <ActivitiesHeader />
        {completedItems.map((item) => (
          <React.Fragment key={item.id}>
            {editingItem && editingItem.id === item.id ? (
              <EditForm
                item={item}
                onSave={handleSaveEdit}
                onCancel={() => setEditingItem(null)}
              />
            ) : (
              <CompletedItem item={item} onEditClick={handleEditClick} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
}