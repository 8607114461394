import { useEffect, useState } from 'react';
import { useAuth } from '../Hooks/useAuth'; // Import useAuth hook
import { supabase } from '../../lib/supabase'; // Import Supabase client

export function useAssignments() {
  const { user, loading: authLoading } = useAuth(); // Get the authenticated user and loading state from useAuth
  const [assignments, setAssignments] = useState([]); // State to store assignments
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch assignments
  useEffect(() => {
    // Only fetch assignments if the user is logged in and auth loading is complete
    if (authLoading || !user) {
      setLoading(false); // Set loading to false if auth is still loading or no user is logged in
      return;
    }

    const fetchAssignments = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch assignments from Supabase
        const { data, error: fetchError } = await supabase
          .from('assignments') // Use the correct table name
          .select('*')
          .eq('user_id', user.id); // Filter by user ID for RLS

        if (fetchError) {
          throw fetchError;
        }

        setAssignments(data || []); // Set assignments data
      } catch (error) {
        console.error('Error fetching assignments:', error);
        setError(error); // Set error if fetching fails
      } finally {
        setLoading(false); // Set loading to false after fetch attempt
      }
    };

    fetchAssignments();
  }, [user, authLoading]); // Re-run effect when user or authLoading changes

  // Update an assignment
  const updateAssignment = async (id, updates) => {
    try {
      const { data, error: updateError } = await supabase
        .from('assignments')
        .update(updates)
        .eq('id', id)
        .select()
        .single();

      if (updateError) {
        throw updateError;
      }

      // Update the local state
      setAssignments((prev) =>
        prev.map((assignment) =>
          assignment.id === id ? { ...assignment, ...data } : assignment
        )
      );
    } catch (error) {
      console.error('Error updating assignment:', error);
      throw error;
    }
  };

  // Delete an assignment
  const deleteAssignment = async (id) => {
    try {
      const { error: deleteError } = await supabase
        .from('assignments')
        .delete()
        .eq('id', id);

      if (deleteError) {
        throw deleteError;
      }

      // Update the local state
      setAssignments((prev) => prev.filter((assignment) => assignment.id !== id));
    } catch (error) {
      console.error('Error deleting assignment:', error);
      throw error;
    }
  };

  return { assignments, loading, error, updateAssignment, deleteAssignment };
}