import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { createAssignment } from '../../api/assignments'; // Import createAssignment directly
import { useAuth } from '../Hooks/useAuth'; // Import useAuth hook
import dayjs from 'dayjs';

export default function AssignmentForm({ onSubmit, onClose }) {
  const { user } = useAuth(); // Get the authenticated user from useAuth
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    due_date: dayjs(), // Initialize with a valid dayjs object
    priority: 'medium',
    assignedTo: '', // Add a field for the assignee's email
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.due_date) {
      alert('Please select a due date');
      return;
    }

    try {
      // Get the logged-in user's email from the useAuth hook
      const assignedBy = user?.email; // Use the authenticated user's email
      const userId = user?.id; // Use the authenticated user's ID

      if (!assignedBy || !userId) {
        throw new Error('No authenticated user found');
      }

      // Get the assignee's email from the form data
      const assignedTo = formData.assignedTo;

      // Ensure due_date is a dayjs object before calling .toDate()
      const dueDate = dayjs(formData.due_date).toDate();

      // Call createAssignment with the required parameters
      await createAssignment(
        formData.title,
        formData.description,
        dueDate,
        assignedBy,
        assignedTo,
        userId,
        formData.priority
      );

      // Call onSubmit and onClose if provided
      if (typeof onSubmit === 'function') onSubmit();
      if (typeof onClose === 'function') onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        New Assignment
      </Typography>

      <Stack spacing={3}>
        <TextField
          fullWidth
          label="Title"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          required
        />

        <TextField
          fullWidth
          label="Description"
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
        />

        <DateTimePicker
          label="Due Date"
          value={formData.due_date}
          onChange={(newValue) => setFormData({ ...formData, due_date: newValue })}
          slotProps={{ textField: { fullWidth: true, required: true } }}
        />

        <TextField
          fullWidth
          label="Assign To (Email)"
          value={formData.assignedTo}
          onChange={(e) => setFormData({ ...formData, assignedTo: e.target.value })}
          required
        />

        <FormControl fullWidth required>
          <InputLabel>Priority</InputLabel>
          <Select
            value={formData.priority}
            onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
            label="Priority"
          >
            <MenuItem value="low">Low</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="high">High</MenuItem>
          </Select>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#00416e',
            '&:hover': {
              backgroundColor: '#003157',
            },
          }}
        >
          Create Assignment
        </Button>
      </Stack>
    </Box>
  );
}