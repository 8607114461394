import React from 'react';
import { Grid } from "@mui/material";
import ClockinButton from './ClockinButton';
import BreakButton from './BreakButton';

export default function TimeCardActions({
  clockbtnlabel,
  workbreak,
  showingHistory,
  clockedin,
  handleClockin,
  handleBreak,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ClockinButton disabled={showingHistory} onClick={handleClockin}>
          {clockbtnlabel}
        </ClockinButton>
      </Grid>
      <Grid item xs={6}>
        <BreakButton disabled={!clockedin || showingHistory} onClick={handleBreak}>
          {workbreak}
        </BreakButton>
      </Grid>
    </Grid>
  );
}