import React from 'react';
import { Typography, Paper, Avatar, Button, CircularProgress } from '@mui/material';
import VerifiedUserOutlined from '@mui/icons-material/VerifiedUserOutlined';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useAuth } from '../Hooks/useAuth'; // Import useAuth to check authentication state

const Main = styled('main')(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  [theme.breakpoints.up(400 + theme.spacing(3))]: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(4),
  backgroundColor: theme.palette.secondary.main,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

function HomePage() {
  const { user, loading, logout } = useAuth(); // Get user, loading state, and logout function

  // Show a loading spinner while checking authentication state
  if (loading) {
    return (
      <Main>
        <StyledPaper>
          <CircularProgress color="secondary" /> {/* Loading spinner */}
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            Loading...
          </Typography>
        </StyledPaper>
      </Main>
    );
  }

  return (
    <Main>
      <StyledPaper>
        <StyledAvatar>
          <VerifiedUserOutlined />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          {user ? `Welcome, ${user.user_metadata?.full_name || user.email}!` : 'Hello Guest!'}
        </Typography>
        {!user ? (
          <>
            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              component={Link}
              to="/auth/register" // Updated to use /auth/register
            >
              Register
            </SubmitButton>
            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              component={Link}
              to="/auth/login" // Updated to use /auth/login
            >
              Login
            </SubmitButton>
          </>
        ) : (
          <>
            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              component={Link}
              to="/dashboard" // Redirect authenticated users to the dashboard
            >
              Go to Dashboard
            </SubmitButton>
            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="error"
              onClick={logout} // Logout button for authenticated users
              sx={{ marginTop: 2 }}
            >
              Logout
            </SubmitButton>
          </>
        )}
      </StyledPaper>
    </Main>
  );
}

export default HomePage;