import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';

/**
 * Create a new assignment.
 * @param {string} title - The title of the assignment.
 * @param {string} description - The description of the assignment.
 * @param {Date} dueDate - The due date of the assignment.
 * @param {string} assignedBy - The email of the user who assigned the task.
 * @param {string} assignedTo - The email of the user to whom the task is assigned.
 * @param {string} userId - The ID of the user who owns the assignment.
 * @param {string} priority - The priority of the assignment (low, medium, high).
 * @returns {Promise} - A promise that resolves when the assignment is created.
 */
export async function createAssignment(title, description, dueDate, assignedBy, assignedTo, userId, priority = 'medium') {
  const assignment = {
    id: uuidv4(), // Generate a new UUID for the assignment
    title,
    description,
    due_date: dueDate.toISOString(), // Convert to ISO string
    assigned_by: assignedBy,
    assigned_to: assignedTo,
    assigned_at: Math.floor(Date.now() / 1000), // Convert to Unix timestamp (seconds)
    status: 'pending', // Default status
    priority, // Priority level
    user_id: userId, // User ID for RLS
  };

  const { data, error } = await supabase
    .from('assignments')
    .insert(assignment);

  if (error) throw error;
  return data;
}

/**
 * Fetch assignments for a specific user.
 * @param {string} userId - The ID of the user.
 * @returns {Promise} - A promise that resolves with the assignments.
 */
export async function getAssignments(userId) {
  const { data, error } = await supabase
    .from('assignments')
    .select('*')
    .eq('user_id', userId) // Filter by user_id for RLS
    .order('due_date', { ascending: true });

  if (error) throw error;
  return data;
}

/**
 * Update the status of an assignment.
 * @param {string} assignmentId - The ID of the assignment.
 * @param {string} status - The new status (pending or completed).
 * @returns {Promise} - A promise that resolves when the status is updated.
 */
export async function updateAssignmentStatus(assignmentId, status) {
  const { error } = await supabase
    .from('assignments')
    .update({ status })
    .eq('id', assignmentId);

  if (error) throw error;
}