import React from 'react';
import { Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  root: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '12pt',
    lineHeight: '1.2',
    color: '#666666',
    border: 'solid 1px',
    backgroundColor: '#FFF',
    textTransform: 'none',
    '&:hover': { backgroundColor: '#8CC63F', color: '#FFF' },
    '&:disabled': { backgroundColor: '#fff', color: '#b3b3b3' },
    minWidth: '140px',
  },
});

const ClockinButton = withStyles(styles)(Button);

export default ClockinButton;