import { useEffect, useState } from 'react';
import {
  getNotifications,
  acknowledgeNotification,
  updateNotificationMeta,
  createNotification,
  getActiveUsers,
} from '../../api/notifications';

const Notifications = ({ userEmail }) => {
  const [notifications, setNotifications] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [message, setMessage] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch notifications and active users on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const notificationsData = await getNotifications(userEmail);
        setNotifications(notificationsData);

        const activeUsersData = await getActiveUsers();
        setActiveUsers(activeUsersData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userEmail]);

  // Handle acknowledging a notification
  const handleAcknowledge = async (notificationId) => {
    try {
      await acknowledgeNotification(notificationId, userEmail);
      setNotifications((prev) =>
        prev.map((n) =>
          n.id === notificationId
            ? {
                ...n,
                recipients: n.recipients.map((r) =>
                  r.email === userEmail
                    ? { ...r, acknowledged_at: Math.floor(Date.now() / 1000) }
                    : r
                ),
              }
            : n
        )
      );
    } catch (error) {
      console.error('Error acknowledging notification:', error);
      setError('Failed to acknowledge notification. Please try again.');
    }
  };

  // Handle replying to a notification
  const handleReply = async (notificationId, replyText) => {
    if (!replyText.trim()) {
      setError('Reply cannot be empty.');
      return;
    }

    try {
      await updateNotificationMeta(notificationId, { reply: replyText });
      setNotifications((prev) =>
        prev.map((n) =>
          n.id === notificationId
            ? {
                ...n,
                notification_meta: { ...n.notification_meta, reply: replyText },
              }
            : n
        )
      );
    } catch (error) {
      console.error('Error adding reply:', error);
      setError('Failed to add reply. Please try again.');
    }
  };

  // Handle sending a notification
  const handleSendNotification = async () => {
    
    console.log('Sending notification:', message, notificationType, selectedRecipients);

    if (!message.trim() || !notificationType.trim() || selectedRecipients.length === 0) {
      setError('Please fill out all fields and select at least one recipient.');
      return;
    }

    

    try {
      await createNotification(userEmail, message, notificationType, selectedRecipients);
      setMessage('');
      setNotificationType('');
      setSelectedRecipients([]);
      alert('Notification sent successfully!');
    } catch (error) {
      console.error('Error sending notification:', error);
      setError('Failed to send notification. Please try again.');
    }
  };

  // Render a single notification
  const renderNotification = (notification) => (
    <div key={notification.id} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
      <p><strong>From:</strong> {notification.sender_email}</p>
      <p><strong>Message:</strong> {notification.message}</p>
      <p><strong>Type:</strong> {notification.notification_type}</p>
      <p><strong>Received:</strong> {new Date(notification.created_at * 1000).toLocaleString()}</p>
      <p><strong>Recipients:</strong></p>
      <ul>
        {notification.recipients.map((recipient) => (
          <li key={recipient.email}>
            {recipient.email} - {recipient.acknowledged_at ? `Read: ${new Date(recipient.acknowledged_at * 1000).toLocaleString()}` : 'Unread'}
          </li>
        ))}
      </ul>
      {notification.notification_meta?.reply && (
        <p><strong>Reply:</strong> {notification.notification_meta.reply}</p>
      )}
      {notification.recipients.some((r) => r.email === userEmail && !r.acknowledged_at) && (
        <button onClick={() => handleAcknowledge(notification.id)}>Mark as Read</button>
      )}
      <div>
        <input
          type="text"
          placeholder="Reply to notification"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleReply(notification.id, e.target.value);
              e.target.value = '';
            }
          }}
        />
      </div>
    </div>
  );

  return (
    <div>
      <h2>Notifications</h2>

      {/* Loading and Error States */}
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Send Notification Form */}
      <div>
        <h3>Send Notification</h3>
        <input
          type="text"
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <input
          type="text"
          placeholder="Notification Type"
          value={notificationType}
          onChange={(e) => setNotificationType(e.target.value)}
        />
        <select
          multiple
          value={selectedRecipients}
          onChange={(e) =>
            setSelectedRecipients(
              Array.from(e.target.selectedOptions, (option) => option.value)
            )
          }
        >
          {activeUsers.map((user) => (
            <option key={user} value={user}>
              {user}
            </option>
          ))}
        </select>
        <button onClick={handleSendNotification}>Send Notification</button>
      </div>

      {/* Notifications List */}
      {notifications.map(renderNotification)}
    </div>
  );
};

export default Notifications;