import React from 'react';
import { List, ListItem, ListItemText } from "@mui/material";

export default function TimeCardEntries({ timecards }) {
  return (
    <List>
      {timecards.map((tc, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={
              <div>
                <span>{tc.utc_start}</span>
                <span>{tc.event_name}</span>
              </div>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}