import React, { useState, useContext } from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Box,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StoreContext } from '../Hooks/store'; // Import StoreContext
//import { interactionTracker } from '../Utils/InteractionTracker'; // Import the singleton
import AssignmentProgress from './AssignmentProgress.jsx';
import AssignmentList from './AssignmentList.jsx';
import AssignmentForm from './AssignmentForm.jsx';
import { makeStyles } from "@mui/styles";

function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`assignment-tabpanel-${index}`}
      aria-labelledby={`assignment-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function AssignmentDialog({ open, onClose }) {
  // Debugging: Log to verify that the component is rendering
  //console.log('AssignmentDialog rendered');

  // Get the context value
  const store = useContext(StoreContext);

  // Debugging: Log the store object to verify it's being retrieved correctly
  //console.log('Store context:', store);

  // Safely destructure modaldialog from the store
  const { modaldialog } = store || {};

  // Debugging: Log modaldialog to verify it's being destructured correctly
  //console.log('modaldialog:', modaldialog);

  // Provide a fallback if modaldialog is undefined
  const [, setLoadedmodal] = modaldialog || [null, () => {}];

  // Debugging: Log setLoadedmodal to verify it's being set correctly
  //console.log('setLoadedmodal:', setLoadedmodal);

  const [tabValue, setTabValue] = useState(0);

  const handleClose = () => {
    // Debugging: Log when handleClose is called
    //console.log('handleClose called');

    setLoadedmodal(''); // Call setLoadedmodal if it exists
    onClose(); // Call the onClose prop to notify the parent component
    //interactionTracker.trackInteraction('button_click', { buttonText: 'AssignmentDialog.close' }); // Use the singleton
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handle form submission
  const handleFormSubmit = (formData) => {
    //console.log('Form submitted with data:', formData);
    setTabValue(1); // Switch to the LIST tab after submission
  };

  const useStyles = makeStyles((theme) => ({
    menuDiv: { marginLeft: '10px' },
    activitiesMenu: {
      fontSize: '12pt',
      verticalAlign: 'middle',
      marginBottom: '5px',
      display: 'inline-block',
    },
  }));

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <AppBar position="static" sx={{ backgroundColor: '#00416e' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Assignments
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="assignment tabs"
          textColor="inherit"
          indicatorColor="secondary"
          sx={{
            '& .MuiTab-root': {
              color: 'white',
              '&.Mui-selected': {
                color: 'white',
              },
            },
          }}
        >
          <Tab label="PROGRESS" />
          <Tab label="LIST" />
          <Tab label="NEW" />
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={0}>
        <AssignmentProgress />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <AssignmentList />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <AssignmentForm onSubmit={handleFormSubmit} onClose={handleClose} />
      </TabPanel>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '100%',
          padding: 2,
          backgroundColor: 'white',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            backgroundColor: '#00416e',
            '&:hover': {
              backgroundColor: '#003157',
            },
          }}
        >
          DONE
        </Button>
      </Box>
    </Dialog>
  );
}