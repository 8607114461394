import React, { useState, useEffect } from 'react';
import { StoreContext } from '../Hooks/store';
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  Button,
  Snackbar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { getTodoItems, createTodoItem, updateTodoItemDescription, toggleTodoItemCompletion, deleteTodoItem } from '../../api/todo';
import { v4 as uuidv4 } from 'uuid';

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Todos({ todolistid }) {
  const {
    todolistsaved,
    todolistheader,
    thisuserid,
    modaldialog,
    tododirtycheck,
  } = React.useContext(StoreContext);

  const [, setLoadedmodal] = modaldialog;
  const [todos, setTodos] = todolistsaved;
  const [dirtytodos, setdirtytodos] = tododirtycheck;
  const [userid] = thisuserid;

  const [newTodo, setNewTodo] = useState('');
  const [isAddingTodo, setIsAddingTodo] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTodoIndex, setEditingTodoIndex] = useState(null);
  const [deletingTodoId, setDeletingTodoId] = useState(null);
  const [delTodoIdx, setDelTodoIdx] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [todoHeader] = todolistheader;

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // Show Snackbar function
  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Fetch todos for the selected todo list
  const fetchTodosForList = async () => {
    console.log('Fetching todos for list:', todolistid); // Debugging
    try {
      if (!todolistid) {
        throw new Error('No todo list selected');
      }

      const todoItems = await getTodoItems(todolistid); // Fetch todos for the selected list
      console.log('Fetched todos:', todoItems); // Debugging
      setTodos(todoItems || []); // Ensure todos is an array, even if API returns null
    } catch (error) {
      console.error('Error fetching todos:', error);
      showSnackbar('Error fetching todos', 'error');
    }
  };

  // Fetch todos when the component opens or the selected todo list changes
  useEffect(() => {
    if (todolistid) {
      fetchTodosForList();
    }
  }, [todolistid]);

  // Handle adding a new todo
  const handleAddedTodo = async () => {
    console.log('Adding new todo:', newTodo.trim()); // Debugging
    if (newTodo.trim() === '') {
      showSnackbar('Todo cannot be empty', 'error');
      return;
    }
  
    // Optimistically update the local state
    const tempTodo = {
      id: uuidv4(), // Temporary ID (will be replaced by the API response)
      user_id: userid,
      list_id: todolistid,
      description: newTodo.trim(),
      completed: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    console.dir(tempTodo, { depth: null }); // Debugging
  
    // Add the temporary todo to the list
    setTodos((prevTodos) => [...(prevTodos || []), tempTodo]);
  
    // Clear the input field
    setNewTodo('');
    setIsAddingTodo(false);
  
    try {
      // Call the API to create the todo item
      const newTodoItem = await createTodoItem(userid, todolistid, newTodo.trim(), false);
  
      // Replace the temporary todo with the actual todo from the API response
      setTodos((prevTodos) =>
        prevTodos.map((todo) => (todo.id === tempTodo.id ? newTodoItem : todo))
      );
  
      showSnackbar('Todo added!', 'success');
    } catch (error) {
      console.error('Error adding todo:', error);
      showSnackbar(`Error adding todo: ${error.message}`, 'error');
  
      // Rollback the optimistic update if the API call fails
      setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== tempTodo.id));
    }
  };

  // Handle toggling todo completion
  const handleToggleTodo = async (index) => {
    const updatedTodos = [...(todos || [])]; // Ensure todos is an array
    const todo = updatedTodos[index];
    if (!todo) return; // Skip if todo is null or undefined

    try {
      await toggleTodoItemCompletion(todo.id, !todo.completed); // Update completion status in the database
      updatedTodos[index].completed = !todo.completed; // Update completion status in the local state
      setTodos(updatedTodos);
      showSnackbar('Todo updated!', 'success');
    } catch (error) {
      console.error('Error toggling todo:', error);
      showSnackbar('Error toggling todo', 'error');
    }
  };

  // Handle saving a todo (updating description)
  const handleSaveTodo = async (index) => {
    const updatedTodos = [...(todos || [])]; // Ensure todos is an array
    const todo = updatedTodos[index];
    if (!todo) return; // Skip if todo is null or undefined

    try {
      await updateTodoItemDescription(todo.id, todo.description); // Save the updated description to the database
      setEditingTodoIndex(null);
      setIsEditing(false);
      showSnackbar('Todo saved!', 'success');
    } catch (error) {
      console.error('Error saving todo:', error);
      showSnackbar('Error saving todo', 'error');
    }
  };

  // Handle deleting a todo
  const handleDeleteTodo = (index) => {
    const todo = todos?.[index]; // Ensure todos is an array
    if (!todo) return; // Skip if todo is null or undefined

    setDeletingTodoId(todo.id);
    setDelTodoIdx(index);
    setIsAlertOpen(true);
  };

  // Handle confirming todo deletion
  const handleConfirmDeleteTodo = async () => {
    try {
      await deleteTodoItem(deletingTodoId); // Delete the todo from the database
      const updatedTodos = (todos || []).filter((_, i) => i !== delTodoIdx); // Ensure todos is an array
      setTodos(updatedTodos);
      setIsAlertOpen(false);
      setDeletingTodoId(null);
      setDelTodoIdx(null);
      showSnackbar('Todo deleted!', 'success');
    } catch (error) {
      console.error('Error deleting todo:', error);
      showSnackbar('Error deleting todo', 'error');
    }
  };

  // Handle canceling todo deletion
  const handleCancelDeleteTodo = () => {
    setIsAlertOpen(false);
    setDeletingTodoId(null);
    setDelTodoIdx(null);
  };

  // Handle updating a todo item (local state only)
  const updateTodoitem = (index, e) => {
    const updatedTodos = [...(todos || [])]; // Ensure todos is an array
    const todo = updatedTodos[index];
    if (!todo) return; // Skip if todo is null or undefined

    todo.description = e.target.value;
    setTodos(updatedTodos);
  };

  // Handle editing a todo item
  const handleEditingTodo = (index) => {
    setEditingTodoIndex(index);
    setIsEditing(true);
  };

  return (
    <>
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isAlertOpen}
        onClose={handleCancelDeleteTodo}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Todo Item</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this todo item? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteTodo} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeleteTodo} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Main Todo List UI */}
      <div style={{ width: '100%', visibility: isAlertOpen ? 'hidden' : 'visible' }}>
        {/* Todo List Header */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            {todoHeader}
          </Typography>
        </div>

        {/* Add New Todo Item */}
        <div>
          {isAddingTodo ? (
            <>
              <TextField
                autoFocus
                multiline
                style={{ width: '100%' }}
                value={newTodo}
                onChange={(e) => setNewTodo(e.target.value)}
                onBlur={() => {
                  if (newTodo.trim() === '') {
                    setIsAddingTodo(false);
                  }
                  if (newTodo.trim() !== '') {
                    handleAddedTodo();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddedTodo();
                  }
                }}
              />
              <div>
                <SaveOutlinedIcon onClick={handleAddedTodo} />
              </div>
            </>
          ) : (
            <Button variant="contained" color="primary" onClick={() => setIsAddingTodo(true)}>
              <AddOutlinedIcon />
            </Button>
          )}
        </div>

        {/* Todo List Items */}
        <div style={{ marginTop: '5px', marginBottom: '5px' }}>
          <List>
            {(todos || []).map((todo, index) => (
              <ListItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={todo?.completed} onChange={() => handleToggleTodo(index)} />
                {editingTodoIndex === index ? (
                  <>
                    <TextField
                      autoFocus
                      multiline
                      style={{ width: '100%' }}
                      value={todo?.description || ''}
                      onChange={(e) => updateTodoitem(index, e)}
                      onBlur={() => handleSaveTodo(index)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSaveTodo(index);
                        }
                      }}
                    />
                    <SaveOutlinedIcon onClick={() => handleSaveTodo(index)} />
                  </>
                ) : (
                  <>
                    <ListItemText primary={todo?.description} onClick={() => handleEditingTodo(index)} />
                    <IconButton aria-label="Edit this item" onClick={() => handleEditingTodo(index)}>
                      <EditNoteOutlinedIcon />
                    </IconButton>
                    <IconButton aria-label="Remove this item" onClick={() => handleDeleteTodo(index)}>
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </>
                )}
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </>
  );
}