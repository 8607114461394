import React, { useEffect, useCallback, useMemo, useContext } from 'react';
import { Btn, StartBtn, PauseBtn, TimerLbl, InputText, Select } from './ActivityTimer.styled';
import { StoreContext } from '../Hooks/store';
import useStickyState from '../Hooks/useStickyState';
import { hashCode } from '../Utils/hashCode';
import { localTime } from '../Utils/localTime';
import { prettyTime } from '../Utils/prettyTime';
import { notify } from "../Toast";
import { getDeviceInfo } from '../Utils/getDeviceInfo';
//import { interactionTracker } from '../Utils/InteractionTracker'; // Import the singleton
import { useAuth } from '../Hooks/useAuth';
import { createActivities, getActivitiesForLast5Days } from '../../api/activities';
import { v4 as uuidv4 } from 'uuid';

export default function ActivityTimer(props) {
  const { user, loading } = useAuth();
  const { activity, color, placeholder, options } = props;
  

  // State Management
  const [customtxt, setCustomtxt] = useStickyState('', `custom${hashCode(`custom${activity}`)}`);
  const [selectedval, setSelectedval] = useStickyState('', `selectedval${hashCode(`selectedval${activity}`)}`);
  const [time, setTime] = useStickyState(new Date().toLocaleTimeString(), `time${hashCode(`time${activity}`)}`);
  const [paused, setPaused] = useStickyState(false, `paused${hashCode(`paused${activity}`)}`);
  const [stopped, setStopped] = useStickyState(true, `stopped${hashCode(`stopped${activity}`)}`);
  const [elapsed, setElapsed] = useStickyState(0, `elapsed${hashCode(`elapsed${activity}`)}`);
  const [label, setLabel] = useStickyState('Start', `label${hashCode(`label${activity}`)}`);
  const [elapsedlabel, setElapsedlabel] = useStickyState('00:00:00', `elapsedlabel${hashCode(`elapsedlabel${activity}`)}`);
  const [tm1, setTm1] = useStickyState(Date.now(), `tm1${hashCode(`tm1${activity}`)}`);
  const [carry, setCarry] = useStickyState(0, `carry${hashCode(`carry${activity}`)}`);
  const [pauselabel, setPauselabel] = useStickyState('Pause', `pauselabel${hashCode(`pauselabel${activity}`)}`);
  const [pausedisabled, setPausedisabled] = useStickyState(true, `pausedisabled${hashCode(`pausedisabled${activity}`)}`);

  const {
    activitiescustom,
    activitiesselected,
    activitiessaved,
    activitiesclockedin,
    thissessionid,
    activitiesaddvisible,
    thisdeviceid,
  } = useContext(StoreContext);

  const [donecustom, setDonecustom] = activitiescustom;
  const [doneselect, setDoneselect] = activitiesselected;
  const [savedactivities, setsavedactivities] = activitiessaved;
  const [clockedin, setClockedin] = activitiesclockedin;
  const [sessionid, setSessionid] = thissessionid;
  const [deviceid, setDeviceid] = thisdeviceid;
  const [showaddactivity, setShowaddactivity] = activitiesaddvisible;

  const selectOptions = Array.isArray(options) ? options : [];

  // Memoized values
  const { clockTimer, selectTimer, customTimer } = useMemo(() => ({
    clockTimer: activity !== 'Custom' && activity !== 'Select',
    selectTimer: activity === 'Select' && selectOptions.length > 0,
    customTimer: activity === 'Custom',
  }), [activity, selectOptions]);

  const formatElapsed = useCallback((totalSecs) => prettyTime(totalSecs * 1000), []);

  // Event Handlers
  const handleStartStop = useCallback(() => {
    if (!user) {
      notify('You must be logged in to perform this action.');
      return;
    }

    if (label === 'Start') {
      startActivity();
    } else {
      stopActivity();
    }
  }, [user, label, clockTimer, sessionid, activity, customtxt, selectedval, elapsedlabel, elapsed, tm1, donecustom, doneselect, deviceid]);

  const startActivity = () => {
    if (clockTimer) {
      //interactionTracker.trackInteraction('button_click', { sessionHash: sessionid, buttonText: 'Activity.Start' }); // Use the singleton
      setClockedin(true);
      setSessionid(`${hashCode(user.email)}_${Date.now()}`);
    }
    setCarry(0);
    setTm1(Date.now());
    setLabel('Stop');
    setStopped(false);
    setPaused(false);
    setPausedisabled(false);
  };

  const stopActivity = () => {
    //interactionTracker.trackInteraction('button_click', { sessionHash: sessionid, buttonText: 'Activity.Stop' }); // Use the singleton
    const rightnow = Date.now();
    resetActivityState(rightnow);

    if (activity === 'Custom') {
      saveToStickyState(customtxt, rightnow);
    }

    if (activity === 'Select') {
      saveToStickyState(selectedval, rightnow);
      setSelectedval('');
    }

    if (clockTimer) {
      setClockedin(false);
      const clockoutItem = makeTempArray('clockout', elapsedlabel, elapsed, tm1, rightnow);
      const completedItems = [...clockoutItem, ...donecustom, ...doneselect];
      const activity_json = {
        count: completedItems.length,
        completed: completedItems,
        deviceInfo: deviceid ? deviceid : hashCode(JSON.stringify(getDeviceInfo())),
      };
      saveActivitiesToDatabase(activity_json);
      getSavedActivities();
    }
  };

  const resetActivityState = (rightnow) => {
    setCustomtxt('');
    setLabel('Start');
    setStopped(true);
    setPaused(false);
    setPausedisabled(true);
    setPauselabel('Pause');
    setTm1(rightnow);
    setElapsedlabel('00:00:00');
  };

  const saveToStickyState = useCallback((doneAct, rightnow) => {
    const temp = makeTempArray(doneAct, elapsedlabel, elapsed, tm1, rightnow);

    // Check for duplicates before adding to donecustom or doneselect
    const isDuplicate = (arr, item) => arr.some((existing) => existing.utc_saved === item.utc_saved);

    if (activity === 'Custom' && !isDuplicate(donecustom, temp[0])) {
      setDonecustom((prev) => [...prev, ...temp]);
    }
    if (activity === 'Select' && !isDuplicate(doneselect, temp[0])) {
      setDoneselect((prev) => [...prev, ...temp]);
    }
  }, [activity, elapsedlabel, elapsed, tm1, donecustom, doneselect, setDonecustom, setDoneselect]);

  const handlePauseResume = useCallback(() => {
    if (!user) {
      notify('You must be logged in to perform this action.');
      return;
    }

    if (pauselabel === 'Pause') {
      pauseActivity();
    } else {
      resumeActivity();
    }
  }, [user, pauselabel, sessionid, carry, tm1, setCarry, setPaused, setStopped, setPauselabel, setTm1]);

  const pauseActivity = () => {
    //interactionTracker.trackInteraction('button_click', { sessionHash: sessionid, buttonText: 'Activity.Resume' }); // Use the singleton
    const sofar = carry + (Date.now() - tm1) / 1000;
    setCarry(sofar);
    setPaused(true);
    setStopped(false);
    setPauselabel('Resume');
  };

  const resumeActivity = () => {
    //interactionTracker.trackInteraction('button_click', { sessionHash: sessionid, buttonText: 'Activity.Pause' }); // Use the singleton
    setPaused(false);
    setPauselabel('Pause');
    setTm1(Date.now());
  };

  const handleOnChange = useCallback((e) => setCustomtxt(e.target.value), []);
  const handleOnSelectedChange = useCallback((e) => setSelectedval(e.target.value), []);
  const toggleAddActivity = useCallback(() => setShowaddactivity(!showaddactivity), [showaddactivity]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const date = new Date();
      setTime(date.toLocaleTimeString());
      if (paused || stopped) return;
      const diff1 = carry + (Date.now() - tm1) / 1000;
      setElapsed(diff1);
      setElapsedlabel(formatElapsed(elapsed));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [time, paused, stopped, carry, tm1, elapsed, formatElapsed]);

  useEffect(() => {
    if (!user) return; // Do not fetch activities if user is not logged in
    notify('Loading....');
    getSavedActivities();
  }, [user]); // Add `user` to the dependency array

  const getSavedActivities = useCallback(async () => {
    if (!user) {
      notify('You must be logged in to fetch activities.');
      setsavedactivities([]);
      return;
    }

    try {
      notify('Refreshing....');
      const activities = await getActivitiesForLast5Days(user.id); // Use the new API call

      if (!activities || typeof activities !== 'object') {
        console.error('Invalid activities data:', activities);
        notify('No activities found or invalid data.');
        setsavedactivities([]);
        return;
      }

      const temp = processActivities(activities);

      // Add day headers after sorting
      const groupedActivities = [];
      let lastDay = null;

      temp.forEach((activity) => {
        const activityDay = new Date(activity.utc_saved).toLocaleDateString('en-us', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });

        if (activityDay !== lastDay) {
          groupedActivities.push({
            utc_start: '',
            utc_end: '',
            activity: activityDay,
            local_start: '',
            local_end: '',
            elapsedlabel: '',
            elapsed: 0,
            utc_saved: activity.utc_saved,
          });
          lastDay = activityDay;
        }

        groupedActivities.push(activity);
      });

      setsavedactivities(groupedActivities);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setsavedactivities([]);
    }
  }, [setsavedactivities, user]); // Add `user` to the dependency array

  const processActivities = (activities) => {
    const temp = [];
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    // Process each activity
    activities.forEach((v) => {
      // Add the activity to the temp array
      temp.push({
        id: v.id, // Include the UUID primary key
        utc_start: v.utc_start,
        utc_end: v.utc_end,
        activity: v.activity,
        local_start: v.local_start || new Date(v.utc_start).toISOString(), // Fallback to utc_start
        local_end: v.local_end || new Date(v.utc_end).toISOString(), // Fallback to utc_end
        elapsedlabel: v.elapsed_label,
        elapsed: v.elapsed,
        utc_saved: v.utc_saved,
      });
    });

    // Sort the activities by `utc_saved` in descending order
    temp.sort((a, b) => b.utc_saved - a.utc_saved);

    return temp;
  };

  const saveActivitiesToDatabase = useCallback(async (activity_json) => {
    if (!user) {
      notify('You must be logged in to save activities.');
      return;
    }

    //interactionTracker.trackInteraction('form_submit', { sessionHash: sessionid, formName: 'Save.Activities' }); // Use the singleton
    try {
      notify('Saving...');
      await createActivities(user.id, activity_json.completed); // Use the new batch insert function
      setDoneselect([]);
      setDonecustom([]);
      notify('Save Completed.');
    } catch (error) {
      notify('Error saving activities.');
      console.log('Error saving activities to the database:', error);
    }
  }, [sessionid, setDoneselect, setDonecustom, user]); // Add `user` to the dependency array

  if (loading) {
    return <div>Loading...</div>; // Show loading state while authentication is being checked
  }

  if (!user) {
    return <div>Please log in to use the activity timer.</div>; // Show message if user is not logged in
  }

  return (
    <>
      {clockTimer && (
        <>
          <TimerLbl inputColor={color}>{activity}</TimerLbl>
          <StartBtn inputColor={color} onClick={handleStartStop}>{label}</StartBtn>
        </>
      )}
      {selectTimer && (
        <>
          <label htmlFor="activitydropdown">{placeholder}</label><br />
          <Select id="activitydropdown" name="activitydropdown" disabled={!clockedin || label === 'Stop'} onChange={handleOnSelectedChange} value={selectedval}>
            {selectOptions.map((opt) => (
              <option key={opt} value={opt}>{opt}</option>
            ))}
          </Select>
          {!showaddactivity && (
            <Btn disabled={!clockedin || label === 'Stop'} inputColor={'green'} onClick={toggleAddActivity}>+</Btn>
          )}
          <StartBtn disabled={!clockedin || !selectedval} inputColor={color} onClick={handleStartStop}>{label}</StartBtn>
        </>
      )}
      {customTimer && (
        <>
          <label htmlFor="custombox">One-time activity:</label><br />
          <InputText
            name="custombox"
            id="custombox"
            maxLength="80"
            disabled={!clockedin || label === 'Stop'}
            value={customtxt === placeholder ? '' : customtxt}
            onChange={handleOnChange}
          />
          <StartBtn
            disabled={!clockedin || !customtxt || customtxt === placeholder}
            inputColor={color}
            onClick={handleStartStop}
          >
            {label}
          </StartBtn>
        </>
      )}
      {label === 'Stop' && (
        <>
          <PauseBtn inputColor={color} onClick={handlePauseResume} disabled={!clockedin || pausedisabled}>{pauselabel}</PauseBtn>
          <TimerLbl inputColor={color}>{elapsedlabel}</TimerLbl>
        </>
      )}
    </>
  );
}

function makeTempArray(doneAct, elapsedlabel, elapsed, tm1, rightnow) {
  return [{
    id: uuidv4(), // Generate a UUID for the primary key
    activity: doneAct,
    elapsedlabel: elapsedlabel,
    elapsed: elapsed,
    utc_start: tm1,
    utc_end: rightnow,
    local_start: new Date(tm1).toISOString(), // Convert to ISO string
    local_end: new Date(rightnow).toISOString(), // Convert to ISO string
  }];
}