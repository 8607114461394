// src/components/Auth/index.js
import React, { useState } from 'react';
import Login from '../Login';
import Register from '../Register';
import ForgotPassword from '../ForgotPassword';
import { Box, Typography, Button } from '@mui/material';

function Auth() {
  const [view, setView] = useState('login'); // 'login', 'register', 'forgot-password'

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {view === 'login' && 'Login'}
        {view === 'register' && 'Register'}
        {view === 'forgot-password' && 'Forgot Password'}
      </Typography>

      {view === 'login' && (
        <>
          <Login />
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <Button onClick={() => setView('register')}>
              Don't have an account? Register
            </Button>
            <Button onClick={() => setView('forgot-password')}>
              Forgot Password?
            </Button>
          </Box>
        </>
      )}

      {view === 'register' && (
        <>
          <Register />
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <Button onClick={() => setView('login')}>
              Already have an account? Login
            </Button>
          </Box>
        </>
      )}

      {view === 'forgot-password' && (
        <>
          <ForgotPassword />
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <Button onClick={() => setView('login')}>
              Back to Login
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Auth;