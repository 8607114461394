import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { StoreContext } from '../Hooks/store';
//import { interactionTracker } from '../Utils/InteractionTracker'; // Import the singleton
import Notifications from './index'; // Import the Notifications component
import { getActiveUsers } from '../../api/notifications'; // Import the function to fetch active users

const useStyles = makeStyles((theme) => ({
  iconwidth: {
    minWidth: '35px !important',
  },
  menuwidth: {
    width: 'inherit',
  },
  menuDiv: { marginLeft: '10px' },
  activitiesMenu: {
    fontSize: '12pt',
    verticalAlign: 'middle',
    marginBottom: '5px',
    display: 'inline-block',
  },
  tctitle: {
    fontFamily: 'Montserrat',
    fontSize: '11pt',
    backgroundColor: '#00416e',
    color: '#fff',
    textAlign: 'center',
  },
  b1: {
    backgroundColor: '#fff',
    minWidth: '320px',
    marginLeft: '-5px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    minWidth: 200,
    marginBottom: theme.spacing(2),
  },
}));

export default function NotificationsDialog(props) {
  const { modaldialog } = React.useContext(StoreContext);
  const [, setLoadedmodal] = modaldialog;

  const [menucolor] = useState(props.rendercolor);
  const classes = useStyles();

  const notificationsIcon =
    menucolor === 'primary' ? (
      <NotificationsIcon color="primary" />
    ) : (
      <NotificationsIcon style={{ color: menucolor }} />
    );

  const [open, setOpen] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]); // State to store active users
  const [selectedRecipients, setSelectedRecipients] = useState([]); // State to store selected recipients

  // Fetch active users when the dialog opens
  useEffect(() => {
    if (open) {
      fetchActiveUsers();
    }
  }, [open]);

  // Function to fetch active users from Supabase
  const fetchActiveUsers = async () => {
    try {
      const users = await getActiveUsers();
      setActiveUsers(users);
    } catch (error) {
      console.error('Error fetching active users:', error);
    }
  };

  // Handle dropdown selection change
  const handleRecipientChange = (event) => {
    setSelectedRecipients(event.target.value);
  };

  function handleOnClose() {
    setLoadedmodal('');
    setOpen(false);
    //interactionTracker.trackInteraction('button_click', { buttonText: 'Notifications.close' }); // Use the singleton
  }

  function handleOnOpen() {
    setLoadedmodal('Notifications');
    setOpen(true);
    //interactionTracker.trackInteraction('page_view', { pageName: 'Notifications' }); // Use the singleton
  }

  return (
    <>
      <div className={classes.menuDiv} onClick={handleOnOpen}>
        {notificationsIcon}
        <span className={classes.activitiesMenu}>&nbsp;&nbsp;Notifications</span>
      </div>
      <Dialog open={open} onClose={handleOnClose}>
        <DialogTitle className={classes.tctitle} onClose={handleOnClose}>
          Notifications
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleOnClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.b1}>
          {/* Dropdown for selecting recipients */}
          <FormControl className={classes.formControl}>
            <InputLabel id="recipient-select-label">Select Recipient(s)</InputLabel>
            <Select
              labelId="recipient-select-label"
              id="recipient-select"
              multiple
              value={selectedRecipients}
              onChange={handleRecipientChange}
              label="Select Recipient(s)"
            >
              {activeUsers.map((email) => (
                <MenuItem key={email} value={email}>
                  {email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div>
            {/* Pass the selected recipients to the Notifications component */}
            <Notifications userEmail="user@example.com" selectedRecipients={selectedRecipients} />
          </div>
          <Divider />
        </DialogContent>
      </Dialog>
    </>
  );
}