import React, { useState, useCallback, useContext } from 'react';
import { Container, Dialog, DialogTitle, DialogContent, Divider, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { notify } from '../Toast';
import CurrentDate from '../Utils/CurrentDate';
import TimeCardDialog from './TimeCardDialog';
import { StoreContext } from '../Hooks/store';
import { useTimeCards } from '../Hooks/useTimeCards';

export default function TimeCard({ open, onClose }) {
  const store = useContext(StoreContext) || {};
  const [user] = store?.user || [null]; // Get the authenticated user from the store
  const userId = user?.id; // Use the authenticated user's ID

  const { timecards, loading, error, createTimeCard } = useTimeCards(userId);

  const [clockedin, setClockedin] = useState(false);
  const [showingHistory, setShowingHistory] = useState(false);
  const [workbreak, setWorkbreak] = useState('Break');
  const [clockbtnlabel, setClockbtnlabel] = useState('Clock In');
  const [status, setStatus] = useState('Clocked Out');
  const [clockinstart, setClockinstart] = useState(0);
  const [breakstart, setBreakstart] = useState(0);
  const [tctime, setTctime] = useState(0);
  const [tcdate, setTcdate] = useState(new Date().toLocaleDateString());
  const [latlon, setLatlon] = useState({ lat: 0, lon: 0, now: 0 });
  const [fwdnavdisabled, setFwdnavdisabled] = useState(true);

  const handleClockin = useCallback(async () => {
    const event_name = !clockedin ? 'Clocked In' : 'Clocked Out';
    const rightnow = Date.now();

    if (!clockedin) {
      setClockedin(true);
      setClockinstart(rightnow);
      setStatus('Clocked In');
      setClockbtnlabel('Clock Out');
    } else {
      setClockedin(false);
      setStatus('Clocked Out');
      setClockbtnlabel('Clock In');
      setClockinstart(0);
    }

    // Create a time card entry
    await createTimeCard({
      event_name,
      event_date: rightnow,
      utc_start: rightnow,
      utc_end: clockedin ? rightnow : rightnow, // Ensure utc_end is not null
      latitude: latlon.lat,
      longitude: latlon.lon,
    });

    notify(`You are ${event_name}`);
    onClose();
  }, [clockedin, createTimeCard, onClose, latlon]);

  // Show a loading indicator while fetching data
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show an error message if there's an error
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '11pt',
            backgroundColor: '#00416e',
            color: '#fff',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <CurrentDate options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: (theme) => theme.spacing(1),
              top: (theme) => theme.spacing(1),
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: '#fff',
            minWidth: '320px',
            marginLeft: '-5px',
          }}
        >
          <TimeCardDialog
            open={open}
            onClose={onClose}
            tcdate={tcdate}
            status={status}
            clockbtnlabel={clockbtnlabel}
            workbreak={workbreak}
            showingHistory={showingHistory}
            clockedin={clockedin}
            handleClockin={handleClockin}
            timecards={timecards}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}