import React from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import WatchOutlinedIcon from '@mui/icons-material/WatchOutlined';
import CurrentDate from '../Utils/CurrentDate';
import { StoreContext } from '../Hooks/store';
import ExerciseTimer from './ExerciseTimer';
//import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';

const useStyles = makeStyles((theme) => ({
    iconwidth: {
        minWidth: '35px !important',
    },
    menuwidth: {
        width: 'inherit'
    },
    menuDiv: { marginLeft: '10px' },
    activitiesMenu: {
        fontSize: '12pt',
        verticalAlign: 'middle',
        marginBottom: '5px',
        display: 'inline-block'
    },
    tctitle: {
        fontFamily: 'Montserrat', fontSize: '11pt',
        backgroundColor: '#00416e', color: '#fff',
        textAlign: 'center'
    },
    b1: {
        backgroundColor: '#fff',
        minWidth: '320px',
        marginLeft: '-5px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function Workout({ open, onClose }) {
    //const { trackInteraction } = useUserInteractionTracker();
    const { modaldialog } = React.useContext(StoreContext);
    const [, setLoadedmodal] = modaldialog;

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const classes = useStyles();

    const handleClose = () => {
        setLoadedmodal('');
        onClose(); // Call the onClose prop to notify the parent component
        //trackInteraction('button_click', { buttonText: 'Workout.close' });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle className={classes.tctitle}>
                <CurrentDate options={dateOptions} />
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.b1}>
                <div><ExerciseTimer /></div>
                <Divider />
            </DialogContent>
        </Dialog>
    );
}