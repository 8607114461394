import React, { useState, useEffect } from 'react';
import { StoreContext } from '../Hooks/store';
import {
  ListItemButton,
  ListItemText,
  Typography,
  Snackbar,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import EditIcon from '@mui/icons-material/Edit';
import { getTodoLists, createTodoList, archiveTodoList, deleteTodoList, updateTodoListName, getTodoItems } from '../../api/todo';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(() => ({
  smallerText: {
    fontSize: '6px !important',
    color: 'grey !important',
    fontWeight: 'lighter !important',
  },
  switchRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    cursor: 'default !important',
  },
  createListContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  actionButtons: {
    display: 'flex',
    gap: '8px',
  },
  listNameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

// Helper function to group todo lists
const groupTodoLists = (todolists, excludeIds = []) => {
  const now = new Date();
  const sevenDaysAgo = new Date(now);
  sevenDaysAgo.setDate(now.getDate() - 7);

  const thirtyDaysAgo = new Date(now);
  thirtyDaysAgo.setDate(now.getDate() - 30);

  const groupedLists = {
    last7Days: [],
    last30Days: [],
    olderThan30Days: {},
  };

  todolists.forEach((list) => {
    // Skip if the list is in the excludeIds array
    if (excludeIds.includes(list.id)) return;

    const listDate = new Date(list.updated_at); // Assuming `updated_at` is the date field
    if (listDate >= sevenDaysAgo) {
      groupedLists.last7Days.push(list);
    } else if (listDate >= thirtyDaysAgo) {
      groupedLists.last30Days.push(list);
    } else {
      const monthYear = listDate.toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!groupedLists.olderThan30Days[monthYear]) {
        groupedLists.olderThan30Days[monthYear] = [];
      }
      groupedLists.olderThan30Days[monthYear].push(list);
    }
  });

  return groupedLists;
};

// TodoListItem Component to avoid code duplication
const TodoListItem = ({
  todolist,
  todoListCounts,
  onSelectTodoList,
  handleArchiveTodoList,
  handleDeleteConfirmation,
  handleEditListName,
  handleSaveListName,
  editingListId,
  editedListName,
  setEditedListName,
  classes,
  settodolistid,
  setTodoHeader,
}) => {
  const { total, completed } = todoListCounts[todolist.id] || { total: 0, completed: 0 };

  return (
    <ListItemButton
      key={todolist.id}
      onClick={() => {
        settodolistid(todolist.id);
        setTodoHeader(todolist.list_name);
        if (typeof onSelectTodoList === 'function') {
          onSelectTodoList(todolist.id, todolist.list_name);
        }
      }}
    >
      {editingListId === todolist.id ? (
        <TextField
          autoFocus
          value={editedListName}
          onChange={(e) => setEditedListName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSaveListName(todolist.id);
            }
          }}
        />
      ) : (
        <ListItemText
          primary={`${todolist.list_name} (${completed}/${total})`}
        />
      )}
      <div className={classes.actionButtons}>
        {editingListId === todolist.id ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleSaveListName(todolist.id);
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEditListName(todolist.id, todolist.list_name);
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleArchiveTodoList(todolist.id, todolist.archived);
          }}
        >
          {todolist.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteConfirmation(todolist.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </ListItemButton>
  );
};

export default function ArchivedTodolists({ onSelectTodoList, onCreateTodoList }) {
  const {
    todolistheader,
    todolistheaderid,
    showarchiveflag,
    mytodolists,
    thisuserid,
  } = React.useContext(StoreContext);

  const classes = useStyles();

  const [showarchived, setShowarchived] = showarchiveflag;
  const [userid] = thisuserid;
  const [todolists, settodolists] = mytodolists;
  const [, settodolistid] = todolistheaderid;
  const [, setTodoHeader] = todolistheader;

  // State for counts
  const [todoListCounts, setTodoListCounts] = useState({});

  // State for creating a new todo list
  const [newListName, setNewListName] = useState('');
  const [isCreatingList, setIsCreatingList] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // Delete confirmation dialog state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);

  // Edit list name state
  const [editingListId, setEditingListId] = useState(null);
  const [editedListName, setEditedListName] = useState('');

  // State for most recent lists
  const [mostRecentLists, setMostRecentLists] = useState([]);

  // State for grouped lists
  const [groupedLists, setGroupedLists] = useState({
    last7Days: [],
    last30Days: [],
    olderThan30Days: {},
  });

  // Show Snackbar function
  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Fetch todo items for each todo list and calculate counts
  const fetchTodoListCounts = async () => {
    const counts = {};
    for (const list of todolists) {
      try {
        const todoItems = await getTodoItems(list.id);
        const total = todoItems.length;
        const completed = todoItems.filter((item) => item.completed).length;
        counts[list.id] = { total, completed };
      } catch (error) {
        console.error(`Error fetching todo items for list ${list.id}:`, error);
        counts[list.id] = { total: 0, completed: 0 };
      }
    }
    setTodoListCounts(counts);
  };

  // Fetch counts when the component mounts or todolists change
  useEffect(() => {
    if (todolists.length > 0) {
      fetchTodoListCounts();
    }
  }, [todolists]);

  // Recalculate mostRecentLists and groupedLists whenever todolists changes
  useEffect(() => {
    const updatedMostRecentLists = [...todolists]
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
      .slice(0, 5); // the 5 most recent todo lists
    setMostRecentLists(updatedMostRecentLists);

    const mostRecentIds = updatedMostRecentLists.map((list) => list.id);
    const updatedGroupedLists = groupTodoLists(todolists, mostRecentIds);
    setGroupedLists(updatedGroupedLists);
  }, [todolists]);

  // Handle creating a new todo list
  const handleCreateTodoList = async () => {
    if (newListName.trim() === '') {
      showSnackbar('List name cannot be empty', 'error');
      return;
    }

    try {
      const newList = await createTodoList(userid, newListName);
      if (typeof onCreateTodoList === 'function') {
        onCreateTodoList(newList);
      }
      setNewListName('');
      setIsCreatingList(false);
      showSnackbar('New list created!', 'success');
    } catch (error) {
      console.error('Error creating todo list:', error);
      showSnackbar('Error creating todo list', 'error');
    }
  };

  // Handle archiving/unarchiving a todo list
  const handleArchiveTodoList = async (listId, isArchived) => {
    try {
      await archiveTodoList(listId, !isArchived);
      const updatedLists = todolists.map((list) =>
        list.id === listId ? { ...list, archived: !isArchived } : list
      );
      settodolists(updatedLists);
      showSnackbar(`List ${isArchived ? 'unarchived' : 'archived'}!`, 'success');
    } catch (error) {
      console.error('Error archiving/unarchiving list:', error);
      showSnackbar('Error archiving/unarchiving list', 'error');
    }
  };

  // Handle opening the delete confirmation dialog
  const handleDeleteConfirmation = (listId) => {
    setListToDelete(listId);
    setDeleteDialogOpen(true);
  };

  // Handle closing the delete confirmation dialog
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setListToDelete(null);
  };

  // Handle deleting a todo list
  const handleDeleteTodoList = async () => {
    if (!listToDelete) return;

    try {
      await deleteTodoList(listToDelete);
      const updatedLists = todolists.filter((list) => list.id !== listToDelete);
      settodolists(updatedLists);
      showSnackbar('List deleted!', 'success');
    } catch (error) {
      console.error('Error deleting list:', error);
      showSnackbar('Error deleting list', 'error');
    } finally {
      handleDeleteDialogClose();
    }
  };

  // Handle editing a todo list name
  const handleEditListName = (listId, currentName) => {
    setEditingListId(listId);
    setEditedListName(currentName);
  };

  // Handle saving the edited list name
  const handleSaveListName = async (listId) => {
    if (editedListName.trim() === '') {
      showSnackbar('List name cannot be empty', 'error');
      return;
    }

    try {
      await updateTodoListName(listId, editedListName);
      const updatedLists = todolists.map((list) =>
        list.id === listId ? { ...list, list_name: editedListName } : list
      );
      settodolists(updatedLists);
      setEditingListId(null);
      setEditedListName('');
      showSnackbar('List name updated!', 'success');
    } catch (error) {
      console.error('Error updating list name:', error);
      showSnackbar('Error updating list name', 'error');
    }
  };

  return (
    <div style={{ height: 300, width: '100%' }}>
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Todo List</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this todo list? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteTodoList} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <h2>All To-Do Lists</h2>

      {/* Create New Todo List */}
      <div className={classes.createListContainer}>
        {isCreatingList ? (
          <>
            <TextField
              autoFocus
              placeholder="New List Name"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCreateTodoList();
                }
              }}
            />
            <Button onClick={handleCreateTodoList}>Create</Button>
            <Button onClick={() => setIsCreatingList(false)}>Cancel</Button>
          </>
        ) : (
          <Button onClick={() => setIsCreatingList(true)}>Create New List</Button>
        )}
      </div>

      {/* Most Recent Section */}
      <Typography variant="h6">Most Recent</Typography>
      {mostRecentLists.map((todolist) => (
        <TodoListItem
          key={todolist.id}
          todolist={todolist}
          todoListCounts={todoListCounts}
          onSelectTodoList={onSelectTodoList}
          handleArchiveTodoList={handleArchiveTodoList}
          handleDeleteConfirmation={handleDeleteConfirmation}
          handleEditListName={handleEditListName}
          handleSaveListName={handleSaveListName}
          editingListId={editingListId}
          editedListName={editedListName}
          setEditedListName={setEditedListName}
          classes={classes}
          settodolistid={settodolistid}
          setTodoHeader={setTodoHeader}
        />
      ))}

      {/* List of Todo Lists */}
      <div>
        {/* Previous 7 Days */}
        <Typography variant="h6">Previous 7 Days</Typography>
        {groupedLists.last7Days.map((todolist) => (
          <TodoListItem
            key={todolist.id}
            todolist={todolist}
            todoListCounts={todoListCounts}
            onSelectTodoList={onSelectTodoList}
            handleArchiveTodoList={handleArchiveTodoList}
            handleDeleteConfirmation={handleDeleteConfirmation}
            handleEditListName={handleEditListName}
            handleSaveListName={handleSaveListName}
            editingListId={editingListId}
            editedListName={editedListName}
            setEditedListName={setEditedListName}
            classes={classes}
            settodolistid={settodolistid}
            setTodoHeader={setTodoHeader}
          />
        ))}

        {/* Previous 30 Days */}
        <Typography variant="h6">Previous 30 Days</Typography>
        {groupedLists.last30Days.map((todolist) => (
          <TodoListItem
            key={todolist.id}
            todolist={todolist}
            todoListCounts={todoListCounts}
            onSelectTodoList={onSelectTodoList}
            handleArchiveTodoList={handleArchiveTodoList}
            handleDeleteConfirmation={handleDeleteConfirmation}
            handleEditListName={handleEditListName}
            handleSaveListName={handleSaveListName}
            editingListId={editingListId}
            editedListName={editedListName}
            setEditedListName={setEditedListName}
            classes={classes}
            settodolistid={settodolistid}
            setTodoHeader={setTodoHeader}
          />
        ))}

        {/* Older than 30 Days */}
        {Object.entries(groupedLists.olderThan30Days).map(([monthYear, lists]) => (
          <div key={monthYear}>
            <Typography variant="h6">{monthYear}</Typography>
            {lists.map((todolist) => (
              <TodoListItem
                key={todolist.id}
                todolist={todolist}
                todoListCounts={todoListCounts}
                onSelectTodoList={onSelectTodoList}
                handleArchiveTodoList={handleArchiveTodoList}
                handleDeleteConfirmation={handleDeleteConfirmation}
                handleEditListName={handleEditListName}
                handleSaveListName={handleSaveListName}
                editingListId={editingListId}
                editedListName={editedListName}
                setEditedListName={setEditedListName}
                classes={classes}
                settodolistid={settodolistid}
                setTodoHeader={setTodoHeader}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

// Add prop-types validation
ArchivedTodolists.propTypes = {
  onSelectTodoList: PropTypes.func,
  onCreateTodoList: PropTypes.func,
};

// Add default props
ArchivedTodolists.defaultProps = {
  onSelectTodoList: () => {},
  onCreateTodoList: () => {},
};