/**
 * Reverse geocodes coordinates into a human-readable address.
 * @param {number} lat - Latitude.
 * @param {number} lng - Longitude.
 * @returns {Promise<string>} - A promise that resolves to the address.
 */
export const reverseGeocode = async (lat, lng) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
    );
    const data = await response.json();
    if (data.address) {
      const { road, house_number, city, state, postcode, country } = data.address;
      return `${road ? road + " " : ""}${house_number ? house_number + ", " : ""}${
        city ? city + ", " : ""
      }${state ? state + " " : ""}${postcode ? postcode + ", " : ""}${country || ""}`;
    }
    return "Address not found";
  } catch (error) {
    console.error("Error reverse geocoding:", error);
    throw new Error("Failed to fetch address.");
  }
};

/**
 * Corrects the address using reverse geocoding.
 * @param {number} lat - Latitude.
 * @param {number} lng - Longitude.
 * @param {function} setAddress - Function to update the address state.
 * @param {function} setIsCorrectingAddress - Function to update the loading state.
 * @returns {Promise<void>}
 */
export const handleCorrectAddress = async (lat, lng, setAddress, setIsCorrectingAddress) => {
  setIsCorrectingAddress(true);
  try {
    const correctedAddress = await reverseGeocode(lat, lng);
    setAddress(correctedAddress);
  } catch (error) {
    console.error("Error correcting address:", error);
    alert("Failed to correct address.");
  } finally {
    setIsCorrectingAddress(false);
  }
};