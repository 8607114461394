import { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';

export function useActivity(userId) {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        // Ensure userId is a valid UUID
        if (!userId || typeof userId !== 'string' || !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(userId)) {
          throw new Error('Invalid user ID. Expected a valid UUID.');
        }

        const { data, error: fetchError } = await supabase
          .from('activity') // Use the correct table name
          .select('*')
          .eq('user_id', userId); // Filter by user_id

        if (fetchError) throw fetchError;
        setActivities(data || []);
      } catch (error) {
        console.error('Error fetching activities:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchActivities();
    }
  }, [userId]);

  const addActivity = async (activity) => {
    try {
      // Ensure userId is a valid UUID
      if (!userId || typeof userId !== 'string' || !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(userId)) {
        throw new Error('Invalid user ID. Expected a valid UUID.');
      }

      const { data, error: insertError } = await supabase
        .from('activity') // Use the correct table name
        .insert([{ ...activity, user_id: userId }])
        .single();

      if (insertError) throw insertError;
      setActivities((prev) => [...prev, data]);
    } catch (error) {
      console.error('Error adding activity:', error);
      setError(error);
    }
  };

  return { activities, loading, error, addActivity };
}