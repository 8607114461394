import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from './TabPanel';
import { a11yProps } from './a11yProps';
import LocationProgress from './LocationProgress';

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function LocationTabs(props) {
    const classes = useStyles();

    const progressMsgs = [
        {
            "name": "gk",
            "date": "May 22 2020",
            "message": "This project is nearly done!"
        },
        {
            "name": "Oleg",
            "date": "May 19 2020",
            "message": "Good progress here."
        },
        {
            "name": "Todd Smith",
            "date": "Apr 1 2020",
            "message": "Let's get this project off the ground."
        }
    ];

    const ProgressItems = React.memo(() => (
        <div>
            {progressMsgs.map((p) => {
                return (
                    <div>
                        <div>
                            <span style={{ marginLeft: '-2px' }}>{p.name}</span>
                            <span style={{ marginRight: '-2px' }}>{p.date}</span>
                        </div>
                        <div>{p.message}</div>
                    </div>
                );
            })}
        </div>
    ));

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        (<div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                    allowScrollButtonsMobile>
                    <Tab label="Progress" {...a11yProps(0)} />
                    <Tab label="Crew" {...a11yProps(1)} />
                    <Tab label="Materials" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <LocationProgress />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ui>
                    <li>*Andrew Black</li>
                    <li>Aron Gray</li>
                    <li>Ben Brooks</li>
                    <li>Chris Wolf</li>
                </ui>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ui>
                    <li>Sand</li>
                    <li>Rocks</li>
                    <li>Gravel</li>
                    <li>Cement</li>
                </ui>
            </TabPanel>
        </div>)
    );
}