import React from 'react';
import { Typography, Box } from '@mui/material';

function VerifyEmail() {
  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Verify Your Email
      </Typography>
      <Typography align="center">
        Please check your email and click the verification link to activate your account.
      </Typography>
    </Box>
  );
}

export default VerifyEmail;