import { useState } from 'react';
import { supabase } from '../../lib/supabase';
import { v4 as uuidv4 } from 'uuid';

/**
 * Custom hook for saving locations.
 * @param {string} userId - The ID of the authenticated user.
 * @returns {Object} - Functions and state for saving locations.
 */
export function useSaveLocation(userId) {
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Save the location to Supabase.
   * @param {string} name - The name of the location.
   * @param {string} address - The address of the location.
   * @param {string} description - The description of the location.
   * @param {number[]} coords - The coordinates of the location [lat, lng].
   */
  const handleSaveLocation = async (name, address, description, coords) => {
    if (!userId) {
      alert("You must be logged in to save a location.");
      return;
    }

    if (!name || !address) {
      alert("Name and address are required.");
      return;
    }

    setIsSaving(true);
    try {
      const locationData = {
        id: uuidv4(), // Generate a UUID for the primary key
        user_id: userId, // User ID from auth.users
        name,
        address,
        description,
        default_icon: "SchoolIcon", // Default icon
        coords: { lat: coords[0], lng: coords[1] }, // Store coordinates as JSONB
        utc_saved: Date.now(), // Current time in milliseconds
      };

      const { data, error } = await supabase
        .from('saved_locations')
        .insert(locationData);

      if (error) throw error;

      alert("Location saved successfully!");
      return data;
    } catch (error) {
      console.error("Error saving location:", error);
      alert("Failed to save location.");
    } finally {
      setIsSaving(false);
    }
  };

  return {
    isSaving,
    handleSaveLocation,
  };
}