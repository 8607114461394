import React from 'react';
import { ReactComponent as BackArrow } from './back_arrow.svg';
import { ReactComponent as ForwardArrow } from './fwd_arrow.svg';

export default function TimeCardHeader({ tcdate, oneDayBack, oneDayForward, fwdnavdisabled }) {
  return (
    <div>
      <span><BackArrow onClick={oneDayBack} width={30} height={30} /></span>
      <span>{tcdate}</span>
      <span><ForwardArrow disabled={fwdnavdisabled} onClick={oneDayForward} width={30} height={30} /></span>
    </div>
  );
}