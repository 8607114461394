import React from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Paper,
  Stack
} from '@mui/material';
import { useAssignments } from '../hooks/useAssignments.js';

export default function AssignmentProgress() {
  const { assignments } = useAssignments();
  
  const total = assignments?.length || 0;
  const completed = assignments?.filter(a => a.status === 'completed').length || 0;
  const progress = total ? (completed / total) * 100 : 0;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Today
      </Typography>

      <Stack spacing={3}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Assignment Progress
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            sx={{ my: 2, height: 8, borderRadius: 4 }}
          />
          <Typography variant="body2" color="textSecondary">
            {completed} of {total} completed
          </Typography>
        </Paper>

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
          <Paper sx={{ p: 3, textAlign: 'center', flex: 1 }}>
            <Typography variant="h6">Pending</Typography>
            <Typography variant="h4">{total - completed}</Typography>
          </Paper>

          <Paper sx={{ p: 3, textAlign: 'center', flex: 1 }}>
            <Typography variant="h6">Completed</Typography>
            <Typography variant="h4">{completed}</Typography>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
}
