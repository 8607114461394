import { supabase } from '../lib/supabase'; // Import Supabase client

// Define table names as constants
const TABLES = {
  USERS_FAVE_QUOTE: 'users_fave_quote',
  CREW_TIME_CARD: 'crew_time_card',
  ACTIVITIES: 'activities',
  CREW_WORK_LOC: 'crew_work_loc',
};

class SupabaseService {
  constructor() {
    this.db = supabase; // Only use the database client
  }

  /**
   * Adds a quote to the `users_fave_quote` table.
   * @param {string} quote - The quote to add.
   * @returns {Promise<Object>} - The inserted data.
   * @throws {Error} - If the user is not authorized or the operation fails.
   */
  async addQuote(quote) {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated. Please log in.');

    const { data, error } = await this.db
      .from(TABLES.USERS_FAVE_QUOTE)
      .upsert({ user_id: user.id, quote });

    if (error) throw new Error(`Failed to add quote: ${error.message}`);
    return data;
  }

  /**
   * Adds a time card entry to the `crew_time_card` table.
   * @param {Object} timeCardData - The time card data to add.
   * @returns {Promise<Object>} - The inserted data.
   * @throws {Error} - If the user is not authorized or the operation fails.
   */
  async addTimeCardEntry(timeCardData) {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated. Please log in.');

    const { data, error } = await this.db
      .from(TABLES.CREW_TIME_CARD)
      .insert({
        user_id: user.id,
        event_name: timeCardData.event_name,
        event_duration_ms: timeCardData.event_duration_ms,
        event_date: timeCardData.event_date,
        utc_start: timeCardData.utc_start,
        utc_end: timeCardData.utc_end,
        utc_saved: Date.now(),
        latitude: timeCardData.latitude,
        longitude: timeCardData.longitude,
      });

    if (error) throw new Error(`Failed to add time card entry: ${error.message}`);
    return data;
  }

  /**
   * Gets time card entries for the last 24 hours.
   * @returns {Promise<Array>} - The time card entries.
   * @throws {Error} - If the user is not authorized or the operation fails.
   */
  async getTimeCardEntriesForLast24Hours() {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated. Please log in.');

    const now = Date.now();
    const oneDayAgo = now - 60 * 60 * 24 * 1000;

    const { data, error } = await this.db
      .from(TABLES.CREW_TIME_CARD)
      .select('*')
      .gte('utc_saved', oneDayAgo)
      .lte('utc_saved', now)
      .eq('user_id', user.id)
      .order('utc_saved', { ascending: true });

    if (error) throw new Error(`Failed to fetch time card entries: ${error.message}`);
    return data;
  }

  /**
   * Gets time card entries for a specific date.
   * @param {string} date - The date in YYYY-MM-DD format.
   * @returns {Promise<Array>} - The time card entries.
   * @throws {Error} - If the user is not authorized or the operation fails.
   */
  async getTimeCardEntriesForDate(date) {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated. Please log in.');

    const startOfDay = new Date(date).setHours(0, 0, 0, 0);
    const endOfDay = new Date(date).setHours(23, 59, 59, 999);

    const { data, error } = await this.db
      .from(TABLES.CREW_TIME_CARD)
      .select('*')
      .gte('utc_saved', startOfDay)
      .lte('utc_saved', endOfDay)
      .eq('user_id', user.id)
      .order('utc_saved', { ascending: true });

    if (error) throw new Error(`Failed to fetch time card entries: ${error.message}`);
    return data;
  }

  /**
   * Adds activities to the `activities` table.
   * @param {Object} activityData - The activity data to add.
   * @returns {Promise<Object>} - The inserted data.
   * @throws {Error} - If the user is not authorized or the operation fails.
   */
  async addActivities(activityData) {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated. Please log in.');

    const { data, error } = await this.db
      .from(TABLES.ACTIVITIES)
      .insert({
        user_id: user.id,
        activity_count: activityData.count,
        activity_data: JSON.stringify(activityData.completed),
        deviceInfo: JSON.stringify(activityData.deviceInfo),
        utc_saved: Date.now(),
      });

    if (error) throw new Error(`Failed to add activities: ${error.message}`);
    return data;
  }

  /**
   * Adds location data to the `crew_work_loc` table.
   * @param {Object} locationData - The location data to add.
   * @returns {Promise<Object>} - The inserted data.
   * @throws {Error} - If the user is not authorized or the operation fails.
   */
  async addLocation(locationData) {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated. Please log in.');

    const { data, error } = await this.db
      .from(TABLES.CREW_WORK_LOC)
      .insert({
        user_id: user.id,
        location_data: locationData,
        utc_saved: Date.now(),
      });

    if (error) throw new Error(`Failed to add location data: ${error.message}`);
    return data;
  }

  /**
   * Gets the current user's favorite quote.
   * @returns {Promise<string>} - The user's favorite quote.
   * @throws {Error} - If the user is not authorized or the operation fails.
   */
  async getCurrentUserQuote() {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated. Please log in.');

    const { data, error } = await this.db
      .from(TABLES.USERS_FAVE_QUOTE)
      .select('quote')
      .eq('user_id', user.id)
      .single();

    if (error) throw new Error(`Failed to fetch quote: ${error.message}`);
    return data?.quote;
  }
}

export default new SupabaseService();