import styled from 'styled-components';

export const Btn = styled.button`
  background: #fff;
  // color: #666666;
  color: ${props => props.inputColor || "#666"};
  font-size: 11pt;
  font-family: 'Montserrat', sans-serif;
  margin: 0.5em;
  padding: 0.25em 0.5em;
  border: 1px solid #666;
  border-radius: 4px;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  line-height: 
  width: 70px;
  &:disabled {
    color: #b3b3b3;
    background: #fff;
    cursor: default;
  }
`;

export const StartBtn = styled(Btn)`
  background: #fff;
  color: ${props => props.inputColor || "#000"};
  &:hover {
    color: #fff;
    background: ${props => props.inputColor || "#8CC63F"};
    border-color: #fff;
  }
`;

export const CancelBtn = styled(Btn)`
  background: #fff;
  color: ${props => props.inputColor || "#b3b3b3"};
  &:hover {
    color: #fff;
    background: ${props => props.inputColor || "#b3b3b3"};
    border-color: #fff;
  }
`;

export const SaveBtn = styled(Btn)`
  background: #fff;
  color: ${props => props.inputColor || "#000"};
  &:hover {
    color: #fff;
    background: ${props => props.inputColor || "#0080BB"};
    border-color: #fff;
  }
`;

export const PauseBtn = styled(Btn)`
  background: #fff;
  color: ${props => props.inputColor || "#000"};
  &:hover {
    color: #fff;
    background: ${props => props.inputColor || "#FF1D25"};
    border-color: #fff;
  }
`;

export const TimerLbl = styled.span`
  font-size: 10pt;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin-top: 10px;
  display: inline-block;  
  // overflow: hidden;
  width: 120px;
  color: ${props => props.inputColor || "#000"};
`;


export const InputText = styled.input.attrs({
  type: 'text',
  // placeholder: props => props.placeholder || 'Please fill',
})`
  padding: 1px 2px; 
  font-size: 10pt;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  width: 120px;
`;

export const Select = styled.select`
  padding: 1px 2px; 
  font-size: 10pt;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  width: 120px;
`;