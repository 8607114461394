import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';

/**
 * Create a new activity entry.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @param {string} activity - The name of the activity.
 * @param {number} elapsed - The elapsed time in seconds.
 * @param {string} elapsedLabel - The label for the elapsed time (e.g., "2 hours").
 * @param {string} localStart - The local start time as a string.
 * @param {string} localEnd - The local end time as a string.
 * @param {number} utcStart - The start time as a Unix timestamp (milliseconds).
 * @param {number} utcEnd - The end time as a Unix timestamp (milliseconds).
 * @param {string} deviceInfo - Information about the device (optional).
 * @returns {Promise} - A promise that resolves when the activity is created.
 */
export async function createActivity(
  userId,
  activity,
  elapsed,
  elapsedLabel,
  localStart,
  localEnd,
  utcStart,
  utcEnd,
  deviceInfo = ''
) {
  const activityData = {
    id: uuidv4(), // Generate a UUID for the primary key
    user_id: userId, // User ID from auth.users
    activity,
    elapsed,
    elapsed_label: elapsedLabel,
    local_start: localStart,
    local_end: localEnd,
    utc_start: utcStart,
    utc_end: utcEnd,
    device_info: deviceInfo,
    utc_saved: Date.now(), // Current time in milliseconds
  };

  const { data, error } = await supabase
    .from('activity')
    .insert(activityData);

  if (error) throw error;
  return data;
}

/**
 * Batch insert multiple activities.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @param {Array} activities - An array of activity objects to insert.
 * @returns {Promise} - A promise that resolves when all activities are created.
 */
export async function createActivities(userId, activities) {
  const activityData = activities.map((activity) => ({
    id: uuidv4(), // Generate a UUID for the primary key
    user_id: userId, // User ID from auth.users
    activity: activity.activity,
    elapsed: activity.elapsed,
    elapsed_label: activity.elapsedlabel,
    local_start: activity.local_start,
    local_end: activity.local_end,
    utc_start: activity.utc_start,
    utc_end: activity.utc_end,
    device_info: activity.deviceInfo || '',
    utc_saved: Date.now(), // Current time in milliseconds
  }));

  const { data, error } = await supabase
    .from('activity')
    .insert(activityData);

  if (error) throw error;
  return data;
}

/**
 * Fetch activities for a specific user.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @returns {Promise} - A promise that resolves with the activities.
 */
export async function getActivities(userId) {
  const { data, error } = await supabase
    .from('activity')
    .select('*')
    .eq('user_id', userId) // Filter by user_id for RLS
    .order('utc_start', { ascending: false }); // Order by start time (most recent first)

  if (error) throw error;
  return data;
}

/**
 * Fetch activities for the last 5 days for a specific user.
 * @param {string} userId - The ID of the user (UUID from auth.users).
 * @returns {Promise} - A promise that resolves with the activities.
 */
export async function getActivitiesForLast5Days(userId) {
  const fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);

  const { data, error } = await supabase
    .from('activity')
    .select('*')
    .eq('user_id', userId) // Filter by user_id for RLS
    .gte('utc_saved', fiveDaysAgo.getTime()) // Filter activities saved in the last 5 days
    .order('utc_start', { ascending: false }); // Order by start time (most recent first)

  if (error) throw error;
  return data;
}

/**
 * Update an activity's elapsed time and label.
 * @param {string} activityId - The ID of the activity (UUID).
 * @param {number} elapsed - The updated elapsed time in seconds.
 * @param {string} elapsedLabel - The updated label for the elapsed time.
 * @returns {Promise} - A promise that resolves when the activity is updated.
 */
export async function updateActivityElapsed(activityId, elapsed, elapsedLabel) {
  const { error } = await supabase
    .from('activity')
    .update({ elapsed, elapsed_label: elapsedLabel })
    .eq('id', activityId); // Filter by the UUID primary key

  if (error) throw error;
}

/**
 * Update an activity in the database.
 * @param {Object} updatedActivity - The updated activity object.
 * @returns {Promise} - A promise that resolves when the activity is updated.
 */
export async function updateActivity(updatedActivity) {
  const { error } = await supabase
    .from('activity')
    .update({
      activity: updatedActivity.activity,
      elapsed: updatedActivity.elapsed,
      elapsed_label: updatedActivity.elapsedlabel,
      local_start: updatedActivity.local_start,
      local_end: updatedActivity.local_end,
      utc_start: updatedActivity.utc_start,
      utc_end: updatedActivity.utc_end,
    })
    .eq('id', updatedActivity.id); // Filter by the UUID primary key

  if (error) throw error;
}

/**
 * Delete an activity.
 * @param {string} activityId - The ID of the activity (UUID).
 * @returns {Promise} - A promise that resolves when the activity is deleted.
 */
export async function deleteActivity(activityId) {
  const { error } = await supabase
    .from('activity')
    .delete()
    .eq('id', activityId); // Filter by the UUID primary key

  if (error) throw error;
}