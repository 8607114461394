import React from 'react';
import { StatusIcon } from '../Shared/StatusIcon';

export default function TimeCardStatus({ statuscolorTimecard, status }) {
  return (
    <div>
      <StatusIcon cls1={statuscolorTimecard} />
      <span>STATUS &nbsp;</span>
      <span>{status}</span>
    </div>
  );
}