import { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabase';

export function useTimeCards(userId) {
  const [timecards, setTimecards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTimeCards = async () => {
      try {
        // Ensure userId is a valid UUID
        if (!userId || typeof userId !== 'string' || !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(userId)) {
          throw new Error('Invalid user ID. Expected a valid UUID.');
        }

        const { data, error: fetchError } = await supabase
          .from('time_card') // Use the correct table name
          .select('*')
          .eq('user_id', userId);

        if (fetchError) throw fetchError;
        setTimecards(data || []);
      } catch (error) {
        console.error('Error fetching time cards:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchTimeCards();
    }
  }, [userId]);

  const createTimeCard = async (timecardData) => {
    try {
      // Ensure userId is a valid UUID
      if (!userId || typeof userId !== 'string' || !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(userId)) {
        throw new Error('Invalid user ID. Expected a valid UUID.');
      }

      const { data, error: insertError } = await supabase
        .from('time_card') // Use the correct table name
        .insert([{ ...timecardData, user_id: userId }])
        .single();

      if (insertError) throw insertError;
      setTimecards((prev) => [...prev, data]);
    } catch (error) {
      console.error('Error creating time card:', error);
      setError(error);
    }
  };

  return { timecards, loading, error, createTimeCard };
}