import React, { useState } from 'react';
import { StoreContext } from '../Hooks/store';
import {
  ListItemButton,
  ListItemText,
  Switch,
  Typography,
  Snackbar,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import { getTodoLists, createTodoList, archiveTodoList, deleteTodoList, updateTodoListName } from '../../api/todo';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import EditIcon from '@mui/icons-material/Edit';

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(() => ({
  smallerText: {
    fontSize: '6px !important',
    color: 'grey !important',
    fontWeight: 'lighter !important',
  },
  switchRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    cursor: 'default !important',
  },
  createListContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  actionButtons: {
    display: 'flex',
    gap: '8px',
  },
  listNameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

export default function ArchivedTodolists({ onSelectTodoList, onCreateTodoList }) {
  const {
    todolistheader,
    todolistheaderid,
    showarchiveflag,
    mytodolists,
    thisuserid,
  } = React.useContext(StoreContext);

  const classes = useStyles();

  const [showarchived, setShowarchived] = showarchiveflag;
  const [userid] = thisuserid;
  const [todolists, settodolists] = mytodolists;
  const [, settodolistid] = todolistheaderid;
  const [, setTodoHeader] = todolistheader;

  // State for creating a new todo list
  const [newListName, setNewListName] = useState('');
  const [isCreatingList, setIsCreatingList] = useState(false);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // Delete confirmation dialog state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);

  // Edit list name state
  const [editingListId, setEditingListId] = useState(null);
  const [editedListName, setEditedListName] = useState('');

  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handle creating a new todo list
  const handleCreateTodoList = async () => {
    if (newListName.trim() === '') {
      showSnackbar('List name cannot be empty', 'error');
      return;
    }

    try {
      const newList = await createTodoList(userid, newListName);
      if (typeof onCreateTodoList === 'function') {
        onCreateTodoList(newList);
      }
      setNewListName('');
      setIsCreatingList(false);
      showSnackbar('New list created!', 'success');
    } catch (error) {
      console.error('Error creating todo list:', error);
      showSnackbar('Error creating todo list', 'error');
    }
  };

  // Handle archiving/unarchiving a todo list
  const handleArchiveTodoList = async (listId, isArchived) => {
    try {
      await archiveTodoList(listId, !isArchived);
      const updatedLists = todolists.map((list) =>
        list.id === listId ? { ...list, archived: !isArchived } : list
      );
      settodolists(updatedLists);
      showSnackbar(`List ${isArchived ? 'unarchived' : 'archived'}!`, 'success');
    } catch (error) {
      console.error('Error archiving/unarchiving list:', error);
      showSnackbar('Error archiving/unarchiving list', 'error');
    }
  };

  // Handle opening the delete confirmation dialog
  const handleDeleteConfirmation = (listId) => {
    setListToDelete(listId);
    setDeleteDialogOpen(true);
  };

  // Handle closing the delete confirmation dialog
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setListToDelete(null);
  };

  // Handle deleting a todo list
  const handleDeleteTodoList = async () => {
    if (!listToDelete) return;

    try {
      await deleteTodoList(listToDelete);
      const updatedLists = todolists.filter((list) => list.id !== listToDelete);
      settodolists(updatedLists);
      showSnackbar('List deleted!', 'success');
    } catch (error) {
      console.error('Error deleting list:', error);
      showSnackbar('Error deleting list', 'error');
    } finally {
      handleDeleteDialogClose();
    }
  };

  // Handle editing a todo list name
  const handleEditListName = (listId, currentName) => {
    setEditingListId(listId);
    setEditedListName(currentName);
  };

  // Handle saving the edited list name
  const handleSaveListName = async (listId) => {
    if (editedListName.trim() === '') {
      showSnackbar('List name cannot be empty', 'error');
      return;
    }

    try {
      await updateTodoListName(listId, editedListName);
      const updatedLists = todolists.map((list) =>
        list.id === listId ? { ...list, list_name: editedListName } : list
      );
      settodolists(updatedLists);
      setEditingListId(null);
      setEditedListName('');
      showSnackbar('List name updated!', 'success');
    } catch (error) {
      console.error('Error updating list name:', error);
      showSnackbar('Error updating list name', 'error');
    }
  };

  return (
    <div style={{ height: 300, width: '100%' }}>
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Todo List</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this todo list? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteTodoList} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <h2>All To-Do Lists</h2>

      {/* Create New Todo List */}
      <div className={classes.createListContainer}>
        {isCreatingList ? (
          <>
            <TextField
              autoFocus
              placeholder="New List Name"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCreateTodoList();
                }
              }}
            />
            <Button onClick={handleCreateTodoList}>Create</Button>
            <Button onClick={() => setIsCreatingList(false)}>Cancel</Button>
          </>
        ) : (
          <Button onClick={() => setIsCreatingList(true)}>Create New List</Button>
        )}
      </div>

      {/* List of Todo Lists */}
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
          <Typography variant="subtitle1">List Name</Typography>
          <Typography variant="subtitle1">Status</Typography>
        </div>
        {todolists.map((todolist) => (
          <ListItemButton
            key={todolist.id}
            onClick={() => {
              console.log('1. in handleShowClick with id: ' + todolist.id + ' and name: ' + todolist.list_name);
              settodolistid(todolist.id);
              setTodoHeader(todolist.list_name);
              if (typeof onSelectTodoList === 'function') {
                onSelectTodoList(todolist.id, todolist.list_name);
              }
            }}
          >
            <div className={classes.listNameContainer}>
              {editingListId === todolist.id ? (
                <TextField
                  autoFocus
                  value={editedListName}
                  onChange={(e) => setEditedListName(e.target.value)}
                  onBlur={() => handleSaveListName(todolist.id)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSaveListName(todolist.id);
                    }
                  }}
                />
              ) : (
                <ListItemText primary={todolist.list_name} />
              )}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditListName(todolist.id, todolist.list_name);
                }}
              >
                <EditIcon />
              </IconButton>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.actionButtons}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleArchiveTodoList(todolist.id, todolist.archived);
                }}
              >
                {todolist.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteConfirmation(todolist.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </ListItemButton>
        ))}
      </div>
    </div>
  );
}

// Add prop-types validation
ArchivedTodolists.propTypes = {
  onSelectTodoList: PropTypes.func,
  onCreateTodoList: PropTypes.func,
};

// Add default props
ArchivedTodolists.defaultProps = {
  onSelectTodoList: () => console.warn('onSelectTodoList not provided'),
  onCreateTodoList: () => console.warn('onCreateTodoList not provided'),
};