import { supabase } from '../lib/supabase';

/**
 * Fetch active users from the auth.users table.
 * @returns {Promise<string[]>} - A promise that resolves with the list of active user emails.
 */
export async function getActiveUsers() {
  try {
    const { data, error } = await supabase
      .from('active_users') // Query the public.active_users view
      .select('email');

    if (error) throw error;

    // Return an array of emails
    return data.map((user) => user.email);
  } catch (error) {
    console.error('Error fetching active users:', error);
    throw error;
  }
}

/**
 * Create a new notification for multiple recipients.
 * @param {string} senderEmail - Email of the sender.
 * @param {string} message - Notification message.
 * @param {string} notificationType - Type of notification (e.g., "comment", "reaction").
 * @param {string[]} recipientEmails - Array of recipient emails.
 * @param {object} notificationMeta - Metadata for the notification (e.g., reactions, comments).
 * @returns {Promise<object>} - A promise that resolves when the notification is created.
 */
export async function createNotification(senderEmail, message, notificationType, recipientEmails, notificationMeta = {}) {
  try {
    // Format recipients array
    const recipients = recipientEmails.map((email) => ({
      email,
      acknowledged_at: null, // Initialize acknowledgment status as null
    }));

    console.log('Creating notification with recipients:', recipients); // Debug log

    if (recipientEmails.length === 0) {
        throw new Error('At least one recipient is required.');
      }

    // Insert the notification into the database
    const { data, error } = await supabase
      .from('notifications')
      .insert([
        {
          sender_email: senderEmail,
          message,
          notification_type: notificationType,
          notification_meta: notificationMeta,
          recipients,
          created_at: Math.floor(Date.now() / 1000), // Epoch timestamp
        },
      ]);

    if (error) throw error;

    console.log('Notification created successfully:', data); // Debug log
    return data;
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
}

/**
 * Fetch notifications for a specific user.
 * @param {string} recipientEmail - Email of the recipient.
 * @returns {Promise<object[]>} - A promise that resolves with the notifications.
 */
/**
 * Fetch notifications for a specific user.
 * @param {string} recipientEmail - Email of the recipient.
 * @returns {Promise<object[]>} - A promise that resolves with the notifications.
 */
export async function getNotifications(recipientEmail) {
    try {
        console.log('Query:', {
            table: 'notifications',
            select: '*',
            contains: { recipients: [{ email: recipientEmail }] },
          });

      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .contains('recipients', [{ email: recipientEmail }]) // Properly formatted JSON array
        .order('created_at', { ascending: false }); // Most recent notifications first
  
      if (error) throw error;
  
      return data;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      throw error;
    }
  }
// export async function getNotifications(recipientEmail) {
//   try {
//     const { data, error } = await supabase
//       .from('notifications')
//       .select('*')
//       .contains('recipients', [{ email: recipientEmail }]) // Filter notifications where the recipient is included
//       .order('created_at', { ascending: false }); // Most recent notifications first

//     if (error) throw error;

//     return data;
//   } catch (error) {
//     console.error('Error fetching notifications:', error);
//     throw error;
//   }
// }

/**
 * Mark a notification as acknowledged (read) by a specific recipient.
 * @param {string} notificationId - The ID of the notification.
 * @param {string} recipientEmail - Email of the recipient.
 * @returns {Promise<object>} - A promise that resolves when the notification is updated.
 */
export async function acknowledgeNotification(notificationId, recipientEmail) {
  try {
    // Fetch the current notification
    const { data: notification, error: fetchError } = await supabase
      .from('notifications')
      .select('recipients')
      .eq('id', notificationId)
      .single();

    if (fetchError) throw fetchError;

    // Update the recipient's acknowledgment status
    const updatedRecipients = notification.recipients.map((recipient) =>
      recipient.email === recipientEmail
        ? { ...recipient, acknowledged_at: Math.floor(Date.now() / 1000) }
        : recipient
    );

    // Update the notification in the database
    const { data, error } = await supabase
      .from('notifications')
      .update({ recipients: updatedRecipients })
      .eq('id', notificationId);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error acknowledging notification:', error);
    throw error;
  }
}

/**
 * Fetch all notifications for the dashboard.
 * @returns {Promise<object[]>} - A promise that resolves with all notifications.
 */
export async function getAllNotificationsForDashboard() {
  try {
    const { data, error } = await supabase
      .from('notifications')
      .select('*')
      .order('created_at', { ascending: false }); // Most recent notifications first

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error fetching all notifications:', error);
    throw error;
  }
}

/**
 * Add a reply, reaction, or comment to the notification metadata.
 * @param {string} notificationId - The ID of the notification.
 * @param {object} newMeta - New metadata to add (e.g., { reply: "Thank you!", reaction: "like" }).
 * @returns {Promise<object>} - A promise that resolves when the notification is updated.
 */
export async function updateNotificationMeta(notificationId, newMeta) {
  try {
    // Fetch the current notification metadata
    const { data: notification, error: fetchError } = await supabase
      .from('notifications')
      .select('notification_meta')
      .eq('id', notificationId)
      .single();

    if (fetchError) throw fetchError;

    // Merge the new metadata with the existing metadata
    const updatedMeta = { ...notification.notification_meta, ...newMeta };

    // Update the notification in the database
    const { data, error } = await supabase
      .from('notifications')
      .update({ notification_meta: updatedMeta })
      .eq('id', notificationId);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error updating notification metadata:', error);
    throw error;
  }
}