import React from 'react';
import { Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  root: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '12pt',
    lineHeight: '1.2',
    color: '#666',
    border: 'solid 1px',
    backgroundColor: '#fff',
    textTransform: 'none',
    '&:hover': { color: '#fff', backgroundColor: '#FBB03B' },
    '&:disabled': { backgroundColor: '#fff', color: '#b3b3b3' },
    minWidth: '140px',
  },
});

const BreakButton = withStyles(styles)(Button);

export default BreakButton;