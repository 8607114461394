import L from 'leaflet'; // Import Leaflet library
import { renderToString } from 'react-dom/server';
import SchoolIcon from '@mui/icons-material/School';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PlaceIcon from '@mui/icons-material/Place';
import HomeIcon from '@mui/icons-material/Home';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

// Map of icon names to their corresponding Material-UI icons
export const iconMap = {
  SchoolIcon: SchoolIcon,
  LocalGroceryStoreIcon: LocalGroceryStoreIcon,
  LocalPoliceIcon: LocalPoliceIcon,
  PlaceIcon: PlaceIcon,
  HomeIcon: HomeIcon,
  LocalHospitalIcon: LocalHospitalIcon,
  LocalHotelIcon: LocalHotelIcon,
  LocalGasStationIcon: LocalGasStationIcon,
  LocalPharmacyIcon: LocalPharmacyIcon,
  LocalPostOfficeIcon: LocalPostOfficeIcon,
};

/**
 * Creates a custom Leaflet icon using a Material-UI icon.
 * @param {React.Component} IconComponent - The Material-UI icon component.
 * @param {string} color - The color of the icon.
 * @returns {L.DivIcon} - A Leaflet DivIcon.
 */
export const createCustomIcon = (IconComponent, color = 'red') => {
  return L.divIcon({
    html: renderToString(<IconComponent style={{ fontSize: '24px', color }} />),
    iconSize: [24, 24], // Size of the icon
    className: 'custom-icon', // Custom class for styling
  });
};