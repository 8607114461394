import React, { useEffect, useRef, useState, useContext, useCallback } from "react";
import styled from "styled-components";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { StoreContext } from '../Hooks/store';
import CustomizedDialogs from '../LocationPopup';
import { iconMap, createCustomIcon } from './mapUtils';
import markersData from '../../data/markers.json';
import { useAuth } from '../Hooks/useAuth';
import { useSaveLocation } from '../Hooks/useSaveLocation';
import { 
  getAllSavedLocations, 
  createSavedLocation, 
  softDeleteLocation, // Replace deleteSavedLocation with softDeleteLocation
  updateSavedLocation,
  getCommentCount 
} from '../../api/savedLocations';
import LocationManagementDialog from './LocationManagementDialog';
import { reverseGeocode } from '../../utils/geocoding';
import MyLocation from '@mui/icons-material/MyLocation'; // Import the MyLocation icon
import { Geocoder as LeafletGeocoder } from 'leaflet-control-geocoder'; // Import the geocoder directly
import 'leaflet-control-geocoder/dist/Control.Geocoder.css'; // Import the geocoder CSS
import 'leaflet-defaulticon-compatibility'; // Fix for broken marker icons
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'; // Optional: Add CSS for better compatibility
import { notify } from '../Toast'; // Import the notify function
import './Map.css'; // Import CSS for custom controls
import tippy from 'tippy.js'; // Import tippy.js
import 'tippy.js/dist/tippy.css'; // Import tippy.js CSS
import CustomMenuControl from './CustomMenuControl'; // Import the custom menu control
import getMenuItems from './menuItems'; // Import the menu items
import TodoList from '../TodoList'; // Import TodoList component
import TimeCard from '../TimeCard'; // Import TimeCard component
import Homework from '../Homework'; // Import Homework component
import AssignmentDialog from '../Assignment/AssignmentDialog'; // Import AssignmentDialog component
import Workout from '../Workout'; // Import Workout component

const Wrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
`;

function Map({ width = "100vw", height = "95vh" }) {
  const { user } = useAuth();
  const { currentuserlocation, geolocation, mapdialog, mapdialogopen, askGeolocation } = useContext(StoreContext);
  const [requestGeolocationFunc] = askGeolocation;
  const [userlocation, setuserlocation] = currentuserlocation;
  const [sharedGeolocation] = geolocation; // Access geolocation state
  const [dialogContent, setDialogContent] = mapdialog;
  const [dialogOpen, setDialogOpen] = mapdialogopen;
  
  const { isSaving, handleSaveLocation } = useSaveLocation(user?.id);

  const mapRef = useRef(null);
  const userMarkerRef = useRef(null); // Ref for the user's current location marker
  const savedLocationsLayerRef = useRef(L.layerGroup());

  const [savedLocations, setSavedLocations] = useState([]);
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [locationDialogData, setLocationDialogData] = useState({
    coordinates: { lat: 0, lng: 0 },
    name: "",
    address: "",
    description: "",
    id: null,
  });
  const [mode, setMode] = useState("save");

  // State to manage the currently open dialog
  const [openDialog, setOpenDialog] = useState(null);

  // Function to handle profile menu opening
  const handleProfileMenuOpen = () => {
    console.log("Profile menu opened");
  };

  // Get menu items by passing setOpenDialog and handleProfileMenuOpen
  const menuItems = getMenuItems(setOpenDialog, handleProfileMenuOpen);

  // Function to handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  // Fetch saved locations from Supabase
  const fetchSavedLocations = useCallback(async () => {
    if (!user) return;

    try {
      const locations = await getAllSavedLocations();
      setSavedLocations(locations);
    } catch (error) {
      console.error("Error fetching saved locations:", error);
      notify("Failed to fetch saved locations.", "error", true);
    }
  }, [user]);

  // Handle edit location
  const handleEditLocation = useCallback((locationId) => {
    const location = savedLocations.find(loc => loc.id === locationId);
    if (location) {
      setLocationDialogData({
        coordinates: { lat: location.coords.lat, lng: location.coords.lng },
        name: location.name,
        address: location.address,
        description: location.description,
        id: location.id, // <-- Ensure this is set correctly
      });
      setMode("edit");
      setLocationDialogOpen(true);
    }
  }, [savedLocations]);
  
  const handleDeleteLocation = useCallback((locationId) => {
    const location = savedLocations.find(loc => loc.id === locationId);
    if (location) {
      setLocationDialogData({
        coordinates: { lat: location.coords.lat, lng: location.coords.lng },
        name: location.name,
        address: location.address,
        description: location.description,
        id: location.id, // <-- Ensure this is set correctly
      });
      setMode("delete");
      setLocationDialogOpen(true);
    }
  }, [savedLocations]);

  // Attach functions to the global scope
  useEffect(() => {
    window.handleEditLocation = handleEditLocation;
    window.handleDeleteLocation = handleDeleteLocation;
    return () => {
      delete window.handleEditLocation;
      delete window.handleDeleteLocation;
    };
  }, [handleEditLocation, handleDeleteLocation]);

  // Update the map center and user marker when geolocation changes
  useEffect(() => {
    if (mapRef.current && sharedGeolocation.lat !== 0 && sharedGeolocation.lon !== 0) {
      const { lat, lon } = sharedGeolocation;

      // Update map center
      mapRef.current.setView([lat, lon], 17);
      notify('Map center updated to: ' + lat + ', ' + lon);

      // Update or create the user marker
      if (userMarkerRef.current) {
        userMarkerRef.current.setLatLng([lat, lon]); // Update marker position
      } else {
        // Create a new marker for the user's current location
        userMarkerRef.current = L.marker([lat, lon], {
          icon: createCustomIcon(MyLocation, '#FF0000'), // Use MyLocation icon
        })
          .addTo(mapRef.current)
          .bindPopup("You are here!"); // Add a popup
      }
    }
  }, [sharedGeolocation]);

  // Update the saved locations layer group
  const updateSavedLocationsLayer = useCallback(async () => {
    savedLocationsLayerRef.current.clearLayers();

    for (const location of savedLocations) {
      const IconComponent = iconMap[location.icon] || iconMap.PlaceIcon;
      const customIcon = createCustomIcon(IconComponent, location.color || '#3f51b5');
      const marker = L.marker([location.coords.lat, location.coords.lng], { icon: customIcon });

      // Fetch comment count for the current location
      let commentCount = 0;
      try {
        commentCount = await getCommentCount(location.id); // Use the new method
      } catch (error) {
        console.error("Error fetching comment count for location:", location.id, error);
      }

      // Bind popup with comment count
      marker.bindPopup(`
        <div style="padding: 10px;">
          <strong>${location.name}</strong>
          <p>${location.address}</p>
          <p>${location.description}</p>
          <p><strong>Coordinates:</strong> ${location.coords.lat.toFixed(6)}, ${location.coords.lng.toFixed(6)}</p>
          <p><strong>Comments:</strong> ${commentCount}</p> <!-- Display comment count -->
          <button onclick="handleEditLocation('${location.id}')" style="padding: 10px; background-color: #4CAF50; color: white; border: none; cursor: pointer; margin-right: 10px;">
            Edit Location
          </button>
          <button onclick="handleDeleteLocation('${location.id}')" style="padding: 10px; background-color: #ff4d4d; color: white; border: none; cursor: pointer;">
            Delete Location
          </button>
        </div>
      `);

      savedLocationsLayerRef.current.addLayer(marker);
    }
  }, [savedLocations]);

  // Handle save location
  const handleSaveLocationCallback = useCallback(
    async (name, address, description, coordinates) => {
      try {
        console.log('Saving location with data:', { name, address, description, coordinates });
  
        const savedLocation = await createSavedLocation(
          user.id, // Pass the user ID
          name,
          address,
          description,
          'PlaceIcon', // Default icon
          [coordinates.lat, coordinates.lng] // Coordinates as [lat, lng]
        );
  
        console.log('Saved location response:', savedLocation);
  
        if (!savedLocation || !savedLocation.id) {
          throw new Error('Failed to save location: No ID returned');
        }
  
        // Show success notification
        notify('Location saved successfully!', 'success');
  
        // Update locationDialogData with the new ID
        setLocationDialogData((prevData) => ({
          ...prevData,
          id: savedLocation.id, // Ensure this is set correctly
        }));
  
        // Fetch updated saved locations
        fetchSavedLocations();
      } catch (error) {
        console.error('Error saving location:', error);
  
        // Show error notification
        notify(`Failed to save location: ${error.message}`, 'error');
      }
    },
    [user, fetchSavedLocations]
  );

  // Handle update location
  const handleUpdateLocation = useCallback(async (name, address, description) => {
    try {
      const updatedData = { name, address, description };
      await updateSavedLocation(locationDialogData.id, updatedData);
      notify("Location updated successfully!", "success", false);

      // Update the local state
      const updatedLocations = savedLocations.map(loc =>
        loc.id === locationDialogData.id
          ? { ...loc, name, address, description }
          : loc
      );
      setSavedLocations(updatedLocations);
    } catch (error) {
      console.error("Error updating location:", error);
      notify("Failed to update location.", "error", true);
    }
  }, [locationDialogData.id, savedLocations]);

  // Handle delete location
  const handleDeleteLocationCallback = useCallback(async () => {
    try {
      await softDeleteLocation(locationDialogData.id, user?.id, "User requested deletion");
      notify("Location deleted successfully!", "success", false);
      fetchSavedLocations();
    } catch (error) {
      console.error("Error deleting location:", error);
      notify("Failed to delete location.", "error", true);
    }
  }, [locationDialogData.id, fetchSavedLocations]);

  // Initialize the map and layers
  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map("map", {
        center: [47.66, -122.33],
        zoom: 10,
        layers: [
          L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            detectRetina: true,
            maxZoom: 20,
            maxNativeZoom: 17,
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
          }),
        ],
        zoomControl: true,
      });

      L.control.scale().addTo(mapRef.current);

      // Add saved locations layer
      savedLocationsLayerRef.current.addTo(mapRef.current);

      // Add markers from markersData
      const markersLayer = L.layerGroup().addTo(mapRef.current);
      markersData.forEach((m) => {
        const IconComponent = iconMap[m.defaultIcon] || iconMap.PlaceIcon;
        const customIcon = createCustomIcon(IconComponent, m.color || 'red');
        const marker = L.marker(m.coords, { icon: customIcon });

        marker.properties = {
          name: m.name,
          address: m.address,
          description: m.description,
        };

        marker.on('click', (e) => {
          setDialogContent(e.target.properties);
          setDialogOpen(true);
          mapRef.current.setView(e.latlng, 17);
        });

        markersLayer.addLayer(marker);
      });

      // Define base layers
      const baseLayers = {
        "OpenStreetMap": L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          detectRetina: true,
          maxZoom: 20,
          maxNativeZoom: 17,
          attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        }),
        "OpenTopoMap": L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: 'Map data: © OpenStreetMap contributors, SRTM | Map style: © OpenTopoMap (CC-BY-SA)'
        }),
        "Esri World Street Map": L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
        }),
        "Esri World Topo Map": L.tileLayer('http://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
          attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
        })
      };

      // Define overlays
      const overlays = {
        "Markers": markersLayer, // Add the markers layer group as an overlay
        "Saved Locations": savedLocationsLayerRef.current, // Add the saved locations layer group as an overlay
      };

      // Add custom controls to the top-right corner
      const customControl1 = L.control({ position: 'topright' });
      customControl1.onAdd = function () {
        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control custom-control');
        const button = L.DomUtil.create('button', '', container);
        button.innerHTML = 'Get My Location'; // Add button text
        button.title = 'Enable location and center the map to my location'; // Add tooltip text
        button.onclick = () => requestGeolocationFunc(); // Trigger geolocation
        L.DomEvent.disableClickPropagation(button); // Prevent map clicks
        
        // Add tippy.js tooltip
        tippy(button, {
          content: 'Center the map to my current location', // Tooltip text
          placement: 'bottom', // Tooltip placement
          arrow: true, // Show arrow
        });

        return container;
      };
      customControl1.addTo(mapRef.current);

      // Add the geocoder control to the top-right corner
      const geocoder = new LeafletGeocoder({
        position: 'topright', // Position of the control on the map
        defaultMarkGeocode: false, // Disable default marker behavior
        placeholder: 'Search for a location...', // Custom placeholder text
        errorMessage: 'Location not found.', // Custom error message
      });
      geocoder.addTo(mapRef.current);

      // Handle geocoding results
      geocoder.on('markgeocode', function (e) {
        const { center, name } = e.geocode;
        mapRef.current.setView(center, 16); // Center the map on the geocoded location
        L.marker(center).addTo(mapRef.current).bindPopup(name).openPopup(); // Add a marker with a popup
      });

      // Add layer control to the map
      L.control.layers(baseLayers, overlays).addTo(mapRef.current);

      // Add custom menu control with menu items
      const customMenuControl = new CustomMenuControl({ position: "topright", menuItems });
      customMenuControl.addTo(mapRef.current);

      // Set OpenStreetMap as the default base layer
      baseLayers["OpenStreetMap"].addTo(mapRef.current);

      // Add right-click event for saving locations
      mapRef.current.on('contextmenu', async (e) => {
        if (!user) {
          notify("You must be logged in to save a location.", "error", true);
          return;
        }

        const { lat, lng } = e.latlng;
        const address = await reverseGeocode(lat, lng);

        setLocationDialogData({
          coordinates: { lat, lng },
          name: "",
          address,
          description: "",
          id: null,
        });
        setMode("save");
        setLocationDialogOpen(true);
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
      if (userMarkerRef.current) {
        userMarkerRef.current.remove(); // Clean up the user marker
        userMarkerRef.current = null;
      }
    };
  }, [user]);

  // Update saved locations layer when savedLocations changes
  useEffect(() => {
    if (mapRef.current) {
      updateSavedLocationsLayer();
    }
  }, [savedLocations, updateSavedLocationsLayer]);

  // Fetch saved locations on mount or when user changes
  useEffect(() => {
    fetchSavedLocations();
  }, [fetchSavedLocations]);

  return (
    <>
      <CustomizedDialogs payload={dialogContent} />
      <LocationManagementDialog
        open={locationDialogOpen}
        onClose={() => setLocationDialogOpen(false)}
        onSaveLocation={handleSaveLocationCallback}
        onUpdateLocation={handleUpdateLocation}
        onDeleteLocation={handleDeleteLocationCallback}
        isSaving={isSaving}
        initialCoordinates={locationDialogData.coordinates}
        initialLocationId={locationDialogData.id}
        initialName={locationDialogData.name}
        initialAddress={locationDialogData.address}
        initialDescription={locationDialogData.description}
        mode={mode}
        fetchSavedLocations={fetchSavedLocations} // Pass the function as a prop
      />

      {/* Render the appropriate dialog based on openDialog state */}
      {openDialog === 'Todolist' && (
        <TodoList open={true} onClose={handleCloseDialog} />
      )}
      {openDialog === 'TimeCard' && (
        <TimeCard open={true} onClose={handleCloseDialog} />
      )}
      {openDialog === 'Homework' && (
        <Homework open={true} onClose={handleCloseDialog} />
      )}
      {openDialog === 'AssignmentDialog' && (
        <AssignmentDialog open={true} onClose={handleCloseDialog} />
      )}
      {openDialog === 'Workout' && (
        <Workout open={true} onClose={handleCloseDialog} />
      )}

      <Wrapper width={width} height={height} id="map" />
    </>
  );
}

export default React.memo(Map);