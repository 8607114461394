import React, { useEffect, useState } from 'react';
import { notify } from "../Toast";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Badge, IconButton, Menu, MenuItem, Typography, Toolbar, Tooltip } from "@mui/material";
import { LocationOn } from '@mui/icons-material'; // Import the LocationOn icon
import MoreIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { withRouter } from '../withRouter';
import TimeCard from '../TimeCard';
import Homework from '../Homework';
import TodoList from '../TodoList';
import Workout from '../Workout';
import NotificationsDialog from '../Notifications/NotificationsDialog';
import { StoreContext } from '../Hooks/store';
import { StatusIcon } from '../Shared/StatusIcon';
import { makeStyles } from '@mui/styles'; // Updated to use makeStyles
import { getDeviceInfo } from '../Utils/getDeviceInfo';
//import { interactionTracker } from '../Utils/InteractionTracker'; // Import the singleton
import { hashCode } from '../Utils/hashCode';
import sqlitedb from '../sqlitedb';
import AssignmentDialog from '../Assignment/AssignmentDialog';
import { useAuth } from '../Hooks/useAuth';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  customizeToolbar: {
    minHeight: '48px', // Adjust the toolbar height if needed
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  statusIconSpan: {
    marginLeft: theme.spacing(1),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenuItem: {
    color: '#000', // Set text color to black
    backgroundColor: '#fff', // Set background color to white
  },
}));

function PrimarySearchAppBar(props) {
  const { user, logout } = useAuth();

  const classes = useStyles(); // Apply styles
  const {
    timecard,
    thisdeviceid,
    thissessionid,
    thisuserid,
    thislocationid,
    modaldialog,
    mysessiondata,
    mytodolistnames,
    myinteractions,
    askGeolocation,
    geolocation
  } = React.useContext(StoreContext);
  const [requestGeolocationFunc] = askGeolocation;
  const [sharedGeolocation] = geolocation; // Access the shared geolocation state

  // Determine if the location has been obtained
  const isLocationObtained = sharedGeolocation.lat !== 0 && sharedGeolocation.lon !== 0;

  const [interactiondata] = myinteractions;
  const [statuscolorTimecard] = timecard;
  const [deviceid, setDeviceid] = thisdeviceid;
  const [sessionid, setSessionid] = thissessionid;
  const [userid, setUserid] = thisuserid;
  const [locationid, setLocationid] = thislocationid;
  const [sessiondata, setsessiondata] = mysessiondata;
  const [kvtodolistnames, setKvtodolistnames] = mytodolistnames;
  const [loadedmodal, setLoadedmodal] = modaldialog;

  const prps = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const menuId = 'primary-search-account-menu';

  const navigate = useNavigate();
  

  const handleAssignment = () => {
    setLoadedmodal('AssignmentDialog');
    handleMenuClose();
  };

  const handleTimecard = () => {
    setLoadedmodal('TimeCard');
    handleMenuClose();
  };

  const saveSessionDataToDatabase = () => {
    const updatedSessionData = {
      ...sessiondata,
      end_at: Date.now()
    };

    const { dvc_hash, dvc_json } = updatedSessionData.device;
    //console.log('to save from session');
    //console.log('dvc_hash:', dvc_hash);
    //console.log('dvc_json:', dvc_json);

    saveSessionToDb(updatedSessionData);
    saveUserInteractionToDb();
  };

  async function saveSessionToDb(sessionJson) {
    //interactionTracker.trackInteraction('form_submit', { formName: 'Save.Sessiondata' }); // Use the singleton
    try {
      //console.dir(sessionJson, { depth: null });
      notify('Saving...');
      await sqlitedb.saveUserSessionData(sessionJson, userid, sessionid);
    } catch (error) {
      notify('Error saving user interactions.');
      //console.log('saveUserInteractions reported error below...');
      //console.dir(sessionJson, { depth: null });
      //console.log(error);
    }
  }

  async function saveUserInteractionToDb() {
    //interactionTracker.trackInteraction('form_submit', { formName: 'Save.Interactions' }); // Use the singleton
    try {
      //console.log(interactiondata);
      notify('Saving...');
      await sqlitedb.saveUserInteractionData(interactiondata, userid, sessionid);
    } catch (error) {
      notify('Error saving user interactions.');
      //console.log('saveUserInteractions reported error below...');
      //console.dir(interactiondata, { depth: null });
      //console.log(error);
    }
  }

  const handleLogin = () => {
    initializeState('handleLogin');
    // interactionTracker.trackInteraction('login', { // Use the singleton
    //   sessionHash: sessionid,
    //   utcTime: new Date().toUTCString(),
    //   localTime: new Date().toLocaleTimeString(),
    // });
  };

  const handleLogout = async () => {
    saveSessionDataToDatabase();
    //interactionTracker.clearSessionData(); // Use the singleton
    await logout(); // Log out of Supabase
    navigate('/', { replace: true }); // Redirect to the homepage
  };

  // useEffect(() => {
  //   interactionTracker.clearSessionData(); // Use the singleton
  //   initializeState('useEffect');
  //   interactionTracker.trackInteraction('page_view', { sessionHash: sessionid, pageName: 'HomePage' }); // Use the singleton
  // }, []);

  const initializeState = (calledBy = '') => {
    const rtnow = Date.now();
    //console.log(`in initializeState-- caller ${calledBy}: rtnow=${rtnow}`);

    if (user) {
      setUserid(hashCode(user.email));
    }

    const obj = JSON.stringify(getDeviceInfo());
    const dvc_obj = {
      dvc_hash: hashCode(obj),
      dvc_json: obj,
    };
    setDeviceid(dvc_obj);

    if (!sessionid || sessionid === '') {
      const newSessionHash = hashCode([sessionid, userid, deviceid, Date.now()].join(","));
      setSessionid(newSessionHash);

      const session_obj = {
        session_hash: newSessionHash,
        device: dvc_obj,
        start_at: rtnow,
        end_at: rtnow,
        session_locations: []
      };

      //console.dir(session_obj, { depth: null });
      setsessiondata(session_obj);
    } else {
      //console.log('session already set: ', sessionid);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCustomMenuOpen = () => {
    handleMenuClose();
  };

  const handleTimecardMenuOpen = () => {
    setLoadedmodal('TimeCard');
    handleMenuClose();
  };

  const handleHomeworkMenuOpen = () => {
    setLoadedmodal('Homework');
    handleMenuClose();
  };

  const handleTodoMenuOpen = () => {
    setLoadedmodal('Todolist');
    handleMenuClose();
  };

  const handleTodoNotesMenuOpen = () => {
    setLoadedmodal('Todonotes');
    handleMenuClose();
  };

  const handleWorkoutMenuOpen = () => {
    setLoadedmodal('Workout');
    handleMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleStatusUpdate = (assignmentId, status) => {
    if (props.onStatusUpdate) {
      props.onStatusUpdate(assignmentId, status);
    }
    handleMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem> {/* Updated to use handleLogout */}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleTodoMenuOpen} className={classes.mobileMenuItem}>
        <IconButton onClick={handleTodoMenuOpen} aria-label="show 4 new mails" color="inherit" size="large">
          <Badge badgeContent={42} color="secondary">
            <MailIcon onClick={handleTodoMenuOpen} />
          </Badge>
        </IconButton>
        <TodoList rendercolor='primary' />
      </MenuItem>
      <MenuItem className={classes.mobileMenuItem}>
        <IconButton aria-label="show 11 new notifications" color="inherit" size="large">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon onClick={handleTodoNotesMenuOpen} />
          </Badge>
        </IconButton>
        <NotificationsDialog rendercolor="primary" />
      </MenuItem>
      <MenuItem onClick={handleTimecardMenuOpen} className={classes.mobileMenuItem}>
        <TimeCard rendercolor='primary' />
      </MenuItem>
      <MenuItem onClick={handleHomeworkMenuOpen} className={classes.mobileMenuItem}>
        <Homework rendercolor='primary' />
      </MenuItem>
      <MenuItem onClick={handleAssignment} className={classes.mobileMenuItem}>
        <AssignmentDialog rendercolor='primary' />
      </MenuItem>
      <MenuItem onClick={handleWorkoutMenuOpen} className={classes.mobileMenuItem}>
        <Workout rendercolor='primary' />
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen} className={classes.mobileMenuItem}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.customizeToolbar}>
          <Typography className={classes.title} variant="h6" noWrap>
            geowork.app
          </Typography>
          <span className={classes.statusIconSpan}>
            <StatusIcon cls1={statuscolorTimecard} />
          </span>
          <Tooltip title={isLocationObtained ? "Location obtained" : "Get My Location"}>
            <IconButton
              color="inherit"
              onClick={requestGeolocationFunc} // This triggers the geolocation request
              aria-label="Get My Location"
            >
              {/* Change the icon color based on whether the location is obtained */}
              <LocationOn style={{ color: isLocationObtained ? 'rgb(140, 198, 63)' : 'inherit' }} />
            </IconButton>
          </Tooltip>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="Todos"
              onClick={handleTodoMenuOpen}
              color="inherit"
              size="large">
              <Badge badgeContent={44} color="secondary">
                <MailIcon />
              </Badge>
              <TodoList rendercolor='#fff' />
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit" size="large">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
              <NotificationsDialog rendercolor="primary" />
            </IconButton>
            <IconButton
              aria-label="Time Card"
              onClick={handleTimecardMenuOpen}
              color="inherit"
              size="large">
              <TimeCard rendercolor='#fff' />
            </IconButton>
            <IconButton
              aria-label="Homework"
              onClick={handleHomeworkMenuOpen}
              color="inherit"
              size="large">
              <Homework rendercolor='#fff' />
            </IconButton>
            <IconButton onClick={handleAssignment}>
              <AssignmentDialog rendercolor='#fff' />
            </IconButton>
            <IconButton
              aria-label="Workout"
              onClick={handleWorkoutMenuOpen}
              color="inherit"
              size="large">
              <Workout rendercolor='#fff' />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large">
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="large">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default withRouter(PrimarySearchAppBar);