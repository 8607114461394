// src/components/App/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Hooks/useAuth';

function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();

  // Show a loading indicator while checking authentication state
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <p>Loading...</p>
      </div>
    );
  }

  // Redirect to the login page if the user is not authenticated
  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  // Render the protected component if the user is authenticated
  return children;
}

export default ProtectedRoute;