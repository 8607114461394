import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Icon for Time Card
import AssignmentIcon from '@mui/icons-material/Assignment'; // Icon for Homework
import WorkoutIcon from '@mui/icons-material/FitnessCenter'; // Icon for Workout

/**
 * Function to generate menu items for the custom menu control.
 * @param {function} setOpenDialog - Function to set the currently open dialog.
 * @param {function} handleProfileMenuOpen - Function to handle profile menu opening.
 * @returns {Array} - Array of menu items.
 */
const getMenuItems = (setOpenDialog, handleProfileMenuOpen) => [
  {
    label: "Todos",
    icon: <MailIcon />,
    onClick: () => setOpenDialog('Todolist'), // Open TodoList dialog
  },
  {
    label: "Time Card",
    icon: <AccessTimeIcon />, // Use a simple icon
    onClick: () => setOpenDialog('TimeCard'), // Open TimeCard dialog
  },
  {
    label: "Homework",
    icon: <AssignmentIcon />, // Use a simple icon
    onClick: () => setOpenDialog('Homework'), // Open Homework dialog
  },
  {
    label: "Assignment",
    icon: <AssignmentIcon />, // Use a simple icon
    onClick: () => setOpenDialog('AssignmentDialog'), // Open AssignmentDialog
  },
  {
    label: "Workout",
    icon: <WorkoutIcon />, // Use a simple icon
    onClick: () => setOpenDialog('Workout'), // Open Workout dialog
  },
  {
    label: "Profile",
    icon: <AccountCircle />,
    onClick: () => handleProfileMenuOpen(), // Handle profile menu opening
  },
];

export default getMenuItems;

// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import TimeCard from '../TimeCard';
// import Homework from '../Homework';
// import AssignmentDialog from '../Assignment/AssignmentDialog';
// import Workout from '../Workout';
// import AccountCircle from '@mui/icons-material/AccountCircle';

// /**
//  * Function to generate menu items for the custom menu control.
//  * @param {function} setOpenDialog - Function to set the currently open dialog.
//  * @param {function} handleProfileMenuOpen - Function to handle profile menu opening.
//  * @returns {Array} - Array of menu items.
//  */
// const getMenuItems = (setOpenDialog, handleProfileMenuOpen) => [
//   {
//     label: "Todos",
//     // icon: <MailIcon />,
//     onClick: () => setOpenDialog('Todolist'), // Open TodoList dialog
//   },
//   // {
//   //   label: "Notifications",
//   //   // icon: <NotificationsIcon />,
//   //   onClick: () => setOpenDialog('Todonotes'), // Open Todonotes dialog
//   // },
//   {
//     label: "Time Card",
//     icon: <TimeCard rendercolor="#fff" />,
//     onClick: () => setOpenDialog('TimeCard'), // Open TimeCard dialog
//   },
//   {
//     label: "Homework",
//     icon: <Homework rendercolor="#fff" />,
//     onClick: () => setOpenDialog('Homework'), // Open Homework dialog
//   },
//   {
//     label: "Assignment",
//     icon: <AssignmentDialog rendercolor="#fff" />,
//     onClick: () => setOpenDialog('AssignmentDialog'), // Open AssignmentDialog
//   },
//   // {
//   //   label: "Workout",
//   //   icon: <Workout rendercolor="#fff" />,
//   //   onClick: () => setOpenDialog('Workout'), // Open Workout dialog
//   // },
//   // {
//   //   label: "Profile",
//   //   icon: <AccountCircle />,
//   //   onClick: () => handleProfileMenuOpen(), // Handle profile menu opening
//   // },
// ];

// export default getMenuItems;